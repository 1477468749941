// Folks game: with clean background. change animation for dust and coupon so dun go outside canvas
// 6 Jul 22: remove checking for browsers
// 21 Jul 22: Added build functionality
// 2 Oct 22: stop start music



/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

 import '@tensorflow/tfjs-backend-webgl';

 import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
 

 //import * as posenet from '@tensorflow-models/posenet';

 import * as posedetection from '@tensorflow-models/pose-detection';

 //import * as cocossd from "@tensorflow-models/coco-ssd";
 

import * as tf from '@tensorflow/tfjs';
 
 import {Camera} from './camera';
 
 import {STATE} from './params';

 import {setBackendAndEnvFlags} from './util';
import { Log, logicalAnd, truncatedNormal } from '@tensorflow/tfjs-core';
import { time } from '@tensorflow/tfjs';
import { getTextureShapeFromLogicalShape } from '@tensorflow/tfjs-backend-webgl/dist/webgl_util';
import { stat } from 'browserify-fs';
 
 tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
      tfjsWasm.version_wasm}/dist/`);
 
var stats = require("stats-lite");

let detector, camera, net,dObj;

let rafId;
var arryLen; 
var data, startTime, timeLapsed=0, b_timer,ls_log="";

var s_myID="",ad_countDown = new Audio(), ad_chickBitten = new Audio(), ad_shuttle = new Audio(), ad_tick = new Audio()
, ad_lastChick = new Audio(),ad_background= new Audio(),ad_charging = new Audio(),ad_snake=new Audio(),ad_ulti=new Audio()
,sSnakeCount,sLevel, n_outPtTime=0,b_SI=true,n_startRestPoseTime=-1, n_1PerLoadTime=18;

//mode: 0 tutorial, 1 challenge, 2 coachLed;
var n_mode=1,o_animate,o_hrm, n_state=-3,ln_SStdev=1.5, n_stablizingTime=1.5, n_waveTime=0,n_lastWBLRequest=-6
,n_pollingFreq=5,n_initSpawnTime=2, n_initGTime=20,n_initEatTime=18, n_bannerDecoTime=5,s_pc="", utterVoice
,b_waveReadyPrompt=false,n_breakTime=10, ln_utterSpeed = 1, b_notSpeaking=true,n_maxLevel=5, s_waitingStatus="", s_waitingRemarks=""
,s_nick="",s_mobile="",s_email="",b_notSending=true,s_recID="",n_diff=0.3,n_server=1,n_selection=0,b_showLine=false
, b_skipframe = false, dExpiry, userInfo="", s_winning="F", n_consolationNum=-1,n_poseLookBack=6;


var b_showBall=false,ln_lastSpawn=0,arryAnimateObj=[],arryExistingIndex=[],ln_videoWidth=0,b_exPose=false,ln_promptTime=-11,n_scoreCount=0
,n_wSFactor=1,n_vSFactor=1,b_moveTree=false,o_tree,ln_clicks=0,b_moveLeft=true, ln_configRA=12, arry_prevPts=[]
,s_comment="", s_prevComment="", n_lastCommentTime=-1,bx, by, bwidth, bheight, n_level=0, n_phase=-1, n_upLimbScore=0,b_actualPlay=true
,d_lastPlay,d_lastReward,n_myCoupon,n_tierAchieved,s_name="", s_teamName="",b_initLoad=true,b_predict=true
, b_displayTime=true,n_sceneID, n_predictID,n_commentID
,n_BLlha=0,n_BLlka=0,n_BLrha=0,n_BLrka=0, b_inEx=true,n_bannerY=0, s_ckAngles="";

var  audioCtx = new AudioContext(),song = new Audio(), source, gainNode,arry_safety=[];


var objPartIndexMap={n:0, le:2, re:5,
                      li:19,ri:20,
                     lm:9,rm:10,
                    lw:15,rw:16,
                    lfi:31,rfi:32,
                    leb:13,reb:14,
                    ls:11,rs:12,
                    lh:23,rh:24

                    };

var exObj;

var synth = window.speechSynthesis;
var utterThis = new SpeechSynthesisUtterance();



///Display and Sound Functions

function assessOverHead(apl){

  logIt("in assessOverHead");

  logIt("apl[15].y<apl[13].y: "+(apl[15].y<apl[13].y) +" apl[16].y< apl[14].y:" +(apl[16].y< apl[14].y) );
  logIt("apl[13].y< apl[7].y: "+(apl[13].y< apl[7].y)+" apl[14].y< apl[8].y:" +(apl[14].y< apl[8].y) );
  logIt("getSignedANGXY(apl[11],apl[13]): "+(getSignedANGXY(apl[11],apl[13]))+" getSignedANGXY(apl[12],apl[14]):" +(getSignedANGXY(apl[12],apl[14])) );
  

  if (
     apl[15].y<apl[13].y && apl[16].y< apl[14].y 
  && apl[13].y< apl[7].y && apl[14].y< apl[8].y
  && getSignedANGXY(apl[11],apl[13])>-35 && getSignedANGXY(apl[11],apl[13])<15
  && getSignedANGXY(apl[12],apl[14])>-15 && getSignedANGXY(apl[12],apl[14])<35){

    return true;

  }else{
    return false;
  }

}

function assessWideElbows(apl){

  logIt("in assessWideElbow");
  logIt("apl[15].y<apl[13].y: "+(apl[15].y<apl[13].y) +" apl[16].y< apl[14].y:" +(apl[16].y< apl[14].y) );
  logIt("apl[13].x> apl[11].x: "+(apl[13].x> apl[11].x)+" apl[12].x> apl[14].x:" +(apl[12].x> apl[14].x) );
  logIt("getANGXY(apl[11],apl[15])>60: "+(getANGXY(apl[11],apl[15])>40)+" getANGXY(apl[12],apl[14])>60:" +(getANGXY(apl[12],apl[16])>40) );
  logIt("getANGXY(apl[13],apl[15])<20: "+(getANGXY(apl[13],apl[15])<40)+" getANGXY(apl[14],apl[16])<20:" +(getANGXY(apl[14],apl[16])<40) );
  if (
     apl[15].y<apl[13].y && apl[16].y< apl[14].y // wY < ebY 
  && apl[13].x> apl[11].x && apl[12].x> apl[14].x //lebX > lsX, rsX>rebX 
  && getANGXY(apl[11],apl[15])>40 && getANGXY(apl[12],apl[16])>40 //abs(ANG sw)>60
  && getANGXY(apl[13],apl[15])<40 && getANGXY(apl[14],apl[16])<40 //abs(ANG ebw)<20
  ){

    return true;

  }else{
    return false;
  }

}  

function assessArmsStrDown(apl){

  logIt("in assessArmsStrDown");
  logIt("apl[19].x>apl[23].x: "+(apl[19].x>apl[23].x) +" apl[20].x< apl[24].x:" +(apl[20].x< apl[24].x) );
  logIt("apl[19].y> apl[23].y: "+(apl[19].y> apl[23].y)+" apl[20].y> apl[24].y:" +(apl[20].y> apl[24].y) );
  logIt("apl[13].y< apl[23].y: "+(apl[13].y< apl[23].y)+" apl[14].y< apl[24].y:" +(apl[14].y< apl[24].y) );
  logIt("getSignedANGXY(apl[11],apl[13]): "+(getSignedANGXY(apl[11],apl[13]))+" getSignedANGXY(apl[12],apl[14]):" +(getSignedANGXY(apl[12],apl[14])) );
  logIt("getSignedANGXY(apl[11],apl[15]): "+(getSignedANGXY(apl[11],apl[15]))+" getSignedANGXY(apl[12],apl[16]):" +(getSignedANGXY(apl[12],apl[16])) );

  var Lhs10pY = (apl[23].y - apl[11].y)*0.2;
  var Rhs10pY = (apl[24].y - apl[12].y)*0.2;

  if (
    apl[19].x>apl[23].x && apl[20].x< apl[24].x //LiX > LhX, RiX < RhX
 && apl[19].y> apl[23].y-Lhs10pY && apl[20].y> apl[24].y-Rhs10pY //LiY > LhY, RiY > RhY
 && apl[13].y< apl[23].y && apl[14].y< apl[24].y //LebY < LhY, RebY< RhY

 && getSignedANGXY(apl[11],apl[13])>-5 && getSignedANGXY(apl[11],apl[13])<20 //0<ANG Lseb<15
 && getSignedANGXY(apl[12],apl[14])>-20 && getSignedANGXY(apl[12],apl[14])<5 //-15<ANG Rseb<0

 && getSignedANGXY(apl[11],apl[15])>5 && getSignedANGXY(apl[11],apl[15])<25 //10<ANG Lsw<20
 && getSignedANGXY(apl[12],apl[16])>-25 && getSignedANGXY(apl[12],apl[16])<-5 //-20<ANG Rsw<-10
 
 ){

   return true;

 }else{
   return false;
 }


}

function assessCurls(apl){

  logIt("in assessCurls");
  logIt("apl[13].y>apl[11].y: "+(apl[13].y>apl[11].y) +" apl[14].y> apl[12].y:" +(apl[14].y> apl[12].y) );
  logIt("apl[13].y>apl[15].y: "+(apl[13].y>apl[15].y)+" apl[14].y> apl[16].y:" +(apl[14].y> apl[16].y) );
  logIt("apl[13].y< apl[23].y: "+(apl[13].y< apl[23].y)+" apl[14].y< apl[24].y:" +(apl[14].y< apl[24].y) );
  logIt("getSignedANGXY(apl[11],apl[13]): "+(getSignedANGXY(apl[11],apl[13]))+" getSignedANGXY(apl[12],apl[14]):" +(getSignedANGXY(apl[12],apl[14])) );
  logIt("getANGby3Points(apl[11],apl[13],apl[15]): "+(getANGby3Points(apl[11],apl[13],apl[15]))+" getANGby3Points(apl[12],apl[14],apl[16]):" +(getANGby3Points(apl[12],apl[14],apl[16])) );


  if (
    apl[13].y>apl[11].y && apl[14].y> apl[12].y //ebY > sY
 && apl[13].y>apl[15].y && apl[14].y> apl[16].y //ebY > wY
 && apl[13].y< apl[23].y && apl[14].y< apl[24].y //LebY < LhY0, RebY< RhY

 && getSignedANGXY(apl[11],apl[13])>-10 && getSignedANGXY(apl[11],apl[13])<25 //-5<ANG Lseb<15
 && getSignedANGXY(apl[12],apl[14])>-25 && getSignedANGXY(apl[12],apl[14])<10 //-15<ANG Rseb<5

 && getANGby3Points(apl[11],apl[13],apl[15])>-20 && getANGby3Points(apl[11],apl[13],apl[15])<20 //-20 <ANG Lsebw < 20
 && getANGby3Points(apl[12],apl[14],apl[16])>-20 && getANGby3Points(apl[12],apl[14],apl[16])<20 //-20 <ANG Rsebw < 20
 
 ){

   return true;

 }else{
   return false;
 }


}

function assessArmsSpread(apl){
  
  
  logIt("in assessArmsSpread");

  var nLRsX = apl[11].x -apl[12].x;

  var nLwebX = apl[15].x - apl[13].x;
  var nLebsX = apl[13].x - apl[11].x;

  var nRsebX = apl[12].x - apl[14].x;
  var nRebwX = apl[14].x - apl[16].x;


  logIt("Left arm Span: "+(apl[15].x - apl[11].x) +" shoulder span:" +(nLRsX) );
  logIt("Right arm Span: "+(apl[12].x - apl[16].x) +" shoulder span:" +(nLRsX) );
  logIt("getANGXY(apl[11],apl[13]): "+(getANGXY(apl[11],apl[13]))+" getANGXY(apl[12],apl[14]):" +(getANGXY(apl[12],apl[14])) );
  logIt("getANGXY(apl[11],apl[15]): "+(getANGXY(apl[11],apl[15]))+" getANGXY(apl[12],apl[16]):" +(getANGXY(apl[12],apl[16])) );
  if (
 //   nLebsX/nLRsX>0.6 && nRsebX/nLRsX>0.6 
 // && nLwebX/nLRsX>0.45 && nRebwX/nLRsX>0.45 //lebX > lsX, rsX>rebX 
  apl[15].x - apl[11].x > nLRsX
  && apl[12].x - apl[16].x > nLRsX
  && getANGXY(apl[11],apl[13])>65 && getANGXY(apl[12],apl[14])>65 //Abs(ANG Lseb)	Abs(ANG Rseb)>65
  && getANGXY(apl[11],apl[15])>65 && getANGXY(apl[12],apl[16])>65 //Abs(ANG Lsw)	Abs(ANG Rsw) >65
  ){

    return true;

  }else{
    return false;
  }


}



class climberObj{
  constructor(anChestHeight,anChestWidth, anChestOffsetY, anCanvasWidth,anCanvasHeight){

    this.nLevel=0;
    this.nChestHeight = anChestHeight;
    this.nChestWidth = anChestWidth
    
    this.nChestOffsetY = anChestOffsetY;
    //this.nClimbPhase=-1;

    this.nCanvasWidth = anCanvasWidth;
    this.nPhase = -1;
    this.nTarget = 5;
    this.nUpDist = anCanvasHeight/this.nTarget;

  }

  reset(){
    this.nLevel=0;
    this.nPhase = -1;

  }

  show(){
    
    if (this.nPhase>-1){

      var nY =0;
      if (this.nLevel>4){
         nY = this.nCanvasHeight - this.nChestHeight;
      }else{
        nY = (this.nLevel*this.nUpDist)-this.nChestHeight + this.nChestOffsetY;
      }
      creImage("T_chestClosed.png", 0 ,nY,this.nChestWidth,this.nChestHeight);


    }else if (this.nPhase === -1){

      creImage("climbStart.PNG", 0.1* camera.canvas.width,0.1* camera.canvas.height, 0.8*camera.canvas.width, 0.8*camera.canvas.height )
      
    }

    //document.getElementById('scoreCount').innerText ="p:"+ this.nPhase+ ", l:"+ this.nLevel;

  }



  assess(apl,aTimeLapsed){

    if(this.nPhase>0){

      if (this.nPhase===1){ // overhead
         
        if (assessOverHead(apl)){  
          setComment(aTimeLapsed, "Down");
          PlaySound(ad_shuttle,"grab.mp3",false);
          this.nPhase = 2;

        }else{
          //setComment(aTimeLapsed, "Reach up high!");
        }
  
      }else if (this.nPhase===2){ // go right
  
        if (assessWideElbows(apl)){
  
          PlaySound(ad_shuttle,"pullDown.mp3",false);
          
          this.nLevel++;
          if (this.nLevel < this.nTarget){
            setComment(aTimeLapsed, "Up!");
          }
          this.nPhase = 1;
          
        }else{
          //setComment(aTimeLapsed, "Pull!");
        }
  
      }

      if ( this.nLevel===this.nTarget){
        this.reset();
        n_level=0;
        n_phase=-1;

        //document.getElementById('scoreCount').innerText ="p:"+ n_phase+ ", l:"+ n_level;

        return true;
      }
       

    }else if (this.nPhase===-1){

      if (assessWideElbows(apl)){
  
        setComment(aTimeLapsed,"Pull down the treasure!");
        
        this.nPhase = 1;

      }else{

        setComment(aTimeLapsed,"Ready for another treasure chest?");
      }

    }

    

    return false;

  }

}

class swingObj{
  constructor(anChestHeight,anChestWidth, anChestOffsetY, anCanvasWidth,anCanvasHeight){

    this.nLevel=0;
    this.nChestHeight = anChestHeight;
    this.nChestWidth = anChestWidth;
    this.nCanvasWidth = anCanvasWidth;
    this.nCanvasHeight = anCanvasHeight;
    //this.nUpDist = anUpDist;
    this.nChestOffsetY = anChestOffsetY;
 
    this.nPhase = -1;
    this.nTarget = 5;

    this.nAnimateTimeStartTime = -1;
    this.nCurrentTime = -1;

    this.pLFlyDist = {xD:0,yD:0};
    this.pRFlyDist = {xD:0,yD:0};
    this.nCoinLength = 0.4*this.nChestHeight;

  }

  reset(){
    this.nLevel=0;
    this.nPhase = -1;

  }

  show(){

    if (this.nPhase===2){


      creImage("T_chestClosed.png", 0 ,0.5*this.nCanvasHeight - 0.5*this.nChestHeight,this.nChestWidth,this.nChestHeight);
      creImage("T_dustCloudC.png", 0 ,0,this.nChestWidth,0.5*this.nCanvasHeight);


    }else if (this.nPhase === 4){
      
      creImage("T_chestClosed.png", 0 ,0.5*this.nCanvasHeight - 0.5*this.nChestHeight,this.nChestWidth,this.nChestHeight);

      creRotatedImage("T_dustCloudC.png", 0 ,0.3*this.nCanvasHeight ,this.nChestWidth,0.5*this.nCanvasHeight, 180);
      //creImage("T_dustCloudC.png", 0 ,0.5*this.nCanvasHeight,this.nChestWidth,0.5*this.nCanvasHeight);

    }else if (this.nPhase === 1 ||this.nPhase===3){

      creImage("T_chestClosed.png", 0 ,0.5*this.nCanvasHeight - 0.5*this.nChestHeight,this.nChestWidth,this.nChestHeight);
  

    }else if (this.nPhase === -1){

      creImage("swingStart.PNG", 0.1*camera.canvas.width ,0.1*camera.canvas.height,0.8*camera.canvas.width,0.8*camera.canvas.height);

    } 

  }

  

  assess(apl,aTimeLapsed){

    if(this.nPhase>0){

      if (this.nPhase===1){ // wide arms
         
        if (assessArmsSpread(apl)){  
          //setComment(aTimeLapsed, "Pull!");
          PlaySound(ad_shuttle,"wipe.mp3",false);
          this.nPhase = 2;
          

        }else{
          setComment(aTimeLapsed, "Spread arms wide!");
        }
        
  
      }else if (this.nPhase===2){ // Overhead
  
        if (assessOverHead(apl)){
  
          setComment(aTimeLapsed, "Swing!");
          this.nPhase = 3;
          
        }else{
          //setComment(aTimeLapsed, "Pull!");
        }      
  
      }else if (this.nPhase===3){ // downward wide arms

        if (assessArmsSpread(apl)){  

          PlaySound(ad_shuttle,"wipe.mp3",false);
          this.nPhase = 4;
          

        }else{
          //setComment(aTimeLapsed, "Reach up high!");
        }
        

      }else if (this.nPhase===4){ // arms str down


        if (assessArmsStrDown(apl)){
  
          this.nLevel++;
          if ( this.nLevel < this.nTarget)
            setComment(aTimeLapsed,"Swing!");

          this.nPhase = 1;        
          
          n_upLimbScore = n_upLimbScore +8;
          //document.getElementById('scoreCount').innerText = n_upLimbScore;
  
        }else{
  
          //setComment(aTimeLapsed,"Into swing position.");
        }

      }

      

      if ( this.nLevel===this.nTarget){
       
        this.reset();

        n_level=0;
        n_phase=-1;

        return true;
      }
        

    }else if (this.nPhase===-1){

      if (assessArmsStrDown(apl)){
  
        setComment(aTimeLapsed,"Clean the treasure chest!");
        this.nPhase = 1;
        
      }else{

        setComment(aTimeLapsed,"Clean position.");
      }

    }

    return false;

  }

}

class liftObj{
  constructor(anChestHeight,anChestWidth, anChestOffsetY, anCanvasWidth,anCanvasHeight){

    this.nLevel=0;
    this.nChestHeight = anChestHeight;
    this.nChestWidth = anChestWidth;
    this.nCanvasWidth = anCanvasWidth;
    this.nCanvasHeight = anCanvasHeight;
    //this.nUpDist = anUpDist;
    this.nChestOffsetY = anChestOffsetY;

    this.nGoldWidth = 0.35*anCanvasWidth;
    this.nGoldHeight = 0.6*anCanvasHeight;

    this.nGoldIngotWidth = 10;
    this.nGoldIngotHeight = 10;

    this.pLi = {x:0,y:0};
    this.pRi = {x:0,y:0};
 
    this.nPhase = -1;
    this.nTarget = 5;

    this.nEndimationTime = -1;
    this.nSpinTime = 0;
    this.nEndimationSteps = 0;
    this.n_consolationNum = -1;
    this.bAnimationCompleted=false;

  }

  reset(){
    this.nLevel=0;
    this.nPhase = -1;
    this.nEndimationTime=-1;
    this.bAnimationCompleted=false;
    this.n_consolationNum = -1;
    this.nEndimationSteps=0;

  }

  show(){

    if ( this.nLevel<this.nTarget){

      if (this.nPhase===1){

        //this.nLevel++;
        //var angle = this.nLevel*20;
        //document.getElementById('scoreCount').innerText = angle;
        //creRotatedImage("T_chestClosed.png", 0 ,this.nCanvasHeight - this.nChestHeight,this.nChestWidth,this.nChestHeight, angle);
        creImage("T_chestClosed.png", 0 ,this.nCanvasHeight - this.nChestHeight,this.nChestWidth,this.nChestHeight);
       
   
      }else if (this.nPhase===2){
  
        creImage("T_chestOpenSmall.png", 0 ,this.nCanvasHeight - this.nChestHeight,this.nChestWidth,this.nChestHeight);
  
      }else if (this.nPhase===-1){
        creImage("liftStart.PNG", 0.1*camera.canvas.width ,0.1*camera.canvas.height,0.8*camera.canvas.width,0.8*camera.canvas.height);
      }


    }else{ // hit target

      logIt("target hit nLevel:" + this.nLevel + " this.nEndimationTime:" + this.nEndimationTime);

      if (this.nEndimationTime===-1){

        this.nEndimationTime = timeLapsed + 6;

        logIt("S mobile: " + s_mobile);

        if (s_mobile.length > 0){

          if (prizeRandomizer(150)){

            s_winning = "T";
            //PostUpdateRec(s_mobile,"T",dExpiry,userInfo);
  
          }
  
          PostUpdateRec(s_mobile,s_winning,dExpiry,userInfo);


        }


      }

      if (this.nEndimationTime > timeLapsed){
        creImage("T_chestOpenBig.png", 0 ,this.nCanvasHeight - this.nChestHeight,this.nChestWidth,this.nChestHeight);


        if (this.nEndimationSteps ===0)
          setComment(timeLapsed,"Congrats!");

        if (this.nEndimationSteps < 18){

          creRotatedImage("T_couponVoucherGrey.png", 0 ,0.25*this.nCanvasHeight ,(40 + this.nEndimationSteps*2)/100 *this.nCanvasWidth,(40 + this.nEndimationSteps*2)/100*this.nCanvasHeight, this.nEndimationSteps*45);

          if (timeLapsed > this.nSpinTime){

            this.nEndimationSteps++;
            this.nSpinTime = timeLapsed + 0.1;

          }


        }else if (this.nEndimationSteps===18){

          this.nEndimationSteps++;
          logIt(" in step 18 step value:" + this.nEndimationSteps);
          if (s_winning === "T"){

            setComment(timeLapsed,"You won voucher!" );

            creImage("T_couponVoucher.png", 0.125*this.nCanvasWidth ,0.125*this.nCanvasHeight ,0.75*this.nCanvasWidth,0.75*this.nCanvasHeight);
  
            //s_winning = "F";

          }else{

            if (this.n_consolationNum===-1){
              this.n_consolationNum= generateRandomIntegerInRange(0,4);


            }
            //var nConsolation = generateRandomIntegerInRange(0,4);

            var arryConsolation =["Good Health","Happiness","Strength","Fitness","Vigor"];

            logIt("setting other comment in step 18");

            setComment(timeLapsed,"You won "+arryConsolation[this.n_consolationNum]+"!" );

            creImage("T_couponVoucherC"+this.n_consolationNum+ ".png", 0.125*this.nCanvasWidth ,0.125*this.nCanvasHeight ,0.75*this.nCanvasWidth,0.75*this.nCanvasHeight);
          

          }


        }else{

          logIt("in step > 18: " + this.nEndimationSteps);

          if (s_winning === "T"){

            //setComment(timeLapsed,"You won voucher!");

            creImage("T_couponVoucher.png", 0.125*this.nCanvasWidth ,0.125*this.nCanvasHeight ,0.75*this.nCanvasWidth,0.75*this.nCanvasHeight);
  
            //s_winning = "F";

          }else{


            creImage("T_couponVoucherC"+this.n_consolationNum+ ".png", 0.125*this.nCanvasWidth ,0.125*this.nCanvasHeight ,0.75*this.nCanvasWidth,0.75*this.nCanvasHeight);
          

          }


        } 


      }else{

        this.bAnimationCompleted = true;
        //s_winning = "F";
        //this.reset();
      }
      
    }
      
  }

  

  assess(apl,aTimeLapsed){

    this.pLi = apl[19];
    this.pRi = apl[20];

    if(this.nPhase>0){

      if (this.nPhase===1){ // curl
        
        

        if (assessCurls(apl)){  
          //setComment(aTimeLapsed, "Pull!");
          PlaySound(ad_shuttle,"openChest.mp3",false);
          
          this.nPhase = 2;
          this.nLevel++;
          if (this.nLevel < this.nTarget){
            setComment(aTimeLapsed, "Almost!" + (this.nTarget-this.nLevel) + " more!" );
          }
          //PlaySound(ad_shuttle,"cashRegister.mp3",false);
          n_upLimbScore = n_upLimbScore + 20;
          //document.getElementById('scoreCount').innerText = n_upLimbScore;

        }else{
          setComment(aTimeLapsed, "Open!");
        }
        
  
      }else if (this.nPhase===2){ 
  
        if (assessArmsStrDown(apl)){
          
          setComment(aTimeLapsed, "Open!");
          this.nGoldIngotHeight = (apl[23].y-apl[11].y)*0.7;
          this.nGoldIngotWidth = 1.8*this.nGoldIngotHeight;
          PlaySound(ad_shuttle,"closeChest.mp3",false);
          this.nPhase = 1;
          
          
        }else{
          //setComment(aTimeLapsed, "Pull!");
        }      
  
      }

      

      if (  this.bAnimationCompleted){
        //setComment(aTimeLapsed,"Lift completed");
        s_winning = "F";
        this.reset();
        return true;
      }
        



    }else if (this.nPhase===-1){

      if (assessArmsStrDown(apl)){
  
        setComment(aTimeLapsed,"Open the chest!");

        this.nGoldIngotHeight = (apl[23].y-apl[11].y)*0.7;
        this.nGoldIngotWidth = 1.8*this.nGoldIngotHeight;
        
        this.nPhase = 1;
        
      }else{

        setComment(aTimeLapsed,"Ready to open?");
      }

      //document.getElementById('scoreCount').innerText = this.nPhase;

    }

    return false;

  }

}

class upLimbExObj{
  constructor(aCanvasWidth,aCanvasHeight){

    this.nLevel=0;
    
    this.anChestHeight = aCanvasHeight/1.5;
     this.anChestWidth = aCanvasWidth;

    this.anCanvasWidth = aCanvasWidth;
    //this.anUpDist = aCanvasHeight/10;
    this.anChestOffsetY = aCanvasHeight/15;

    this.oClimbObj = new climberObj(this.anChestHeight, this.anChestWidth,this.anChestOffsetY,aCanvasWidth,aCanvasHeight);
    this.oSwingObj = new swingObj(this.anChestHeight, this.anChestWidth,this.anChestOffsetY,aCanvasWidth,aCanvasHeight);
    this.oLiftObj  = new liftObj(this.anChestHeight, this.anChestWidth,this.anChestOffsetY,aCanvasWidth,aCanvasHeight); 

    this.nGameStartTime=0;

    this.oSelector = new selectionObj(aCanvasWidth,["play 3 minute","play 5 minutes","play continuous"]
    ,["F_3min.png","F_5min.png","F_unlimited.png"],[180,300,99999]);

    /*

        this.nStage = 1;
    this.nGameDuration=99999;

    this.nStage = -3;
    this.nGameDuration=-1;
    */

    this.nStage = -3;
    this.nGameDuration=-1;

  }

  show(aTimeLapsed){

    if (this.nStage>-1){

      if(Math.round( aTimeLapsed - this.nGameStartTime)<this.nGameDuration){

        document.getElementById('gameTime').innerText =  Math.round( aTimeLapsed - this.nGameStartTime);

        if (this.nStage===1){ // Climb

          //this.oClimbObj.nLevel = this.nLevel;
          //this.oClimbObj.nPhase = n_phase;
          this.oClimbObj.show();

    
        }else if (this.nStage===2){ // Swing
    
          //this.oSwingObj.nLevel = this.nLevel;
          //this.oSwingObj.nPhase = n_phase;
          this.oSwingObj.show();
    
        }else if(this.nStage===0){ //Lift

          //this.oLiftObj.nLevel = this.nLevel;
          //this.oLiftObj.nPhase = n_phase;
          this.oLiftObj.show();

        }


      }else if (Math.round( aTimeLapsed - this.nGameStartTime)>=this.nGameDuration && document.getElementById('gameTime').innerText!==this.nGameDuration){

        this.endGameProcess(this.nGameDuration);
        
      }
       
    

    }else if (this.nStage===-3){

      if (document.getElementById('loading').style.display!=="none"){
        document.getElementById('loading').style.display = "none";
        document.getElementById('loadingStats').style.display = "none";
        document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
      }
      this.oSelector.showSelection();

    }else if (this.nStage===-1){

      creImage("climbStart.PNG", 0.1* camera.canvas.width,0.1* camera.canvas.height, 0.8*camera.canvas.width, 0.8*camera.canvas.height );


    }

    //creImage("T_pine.png", this.anCanvasWidth- this.anChestWidth ,this.nLevel*this.anUpDist + this.anChestOffsetY, this.anChestWidth,this.anChestHeight );


  }

  assess(apl, aTimeLapsed,aar_prevPts){

    if (this.nStage>-1 ){
   
      var bContinue = this.oSelector.checkContinue(apl);
      var bStageCompleted = false;

      if (bContinue){

        if (this.nStage===1|| this.nStage===3){ // Climb

          bStageCompleted = this.oClimbObj.assess(apl,aTimeLapsed);

    
        }else if (this.nStage===2|| this.nStage===4){ // Swing
    
          bStageCompleted = this.oSwingObj.assess(apl,aTimeLapsed);
    
        }else if(this.nStage===0){ //Lift

          bStageCompleted = this.oLiftObj.assess(apl,aTimeLapsed);

        }

        if (bStageCompleted){

          //setComment(aTimeLapsed,"Stage " +this.nStage + " completed" );
          //this.nStage = (++this.nStage)%5;

          this.nStage = (++this.nStage)%3; // demo only

          if (this.nStage===1){

            //PlaySound(ad_background,"jingShe.mp3",true);

          }else if (this.nStage===2){
            //PlaySound(ad_background,"gongXiGongXi.mp3",true);
          }else if (this.nStage===0){
            //PlaySound(ad_background,"zcjb.mp3",true);
          }

        }
 
      }else{

        this.endGameProcess(Math.round( aTimeLapsed - this.nGameStartTime));

      }
    }else if (this.nStage===-1){

      if (assessWideElbows(apl)){
        this.nStage = 1;
        this.nGameStartTime = aTimeLapsed;
      }else{

        setComment(aTimeLapsed,"Let's bring the treasure chest down. Into position.");
      }


    }else if (this.nStage===-3){

      this.nGameDuration = this.oSelector.assessSelection(apl);

      if (this.nGameDuration>0){
        
        this.nStage = -1;
        setComment(aTimeLapsed,"Let's bring down the treasure chest.");

      }

    }

    this.n_bannerY= (apl[11].y>apl[12].y)?apl[11].y:apl[12].y;

  }

  endGameProcess(aDuration){


    b_inEx=false;
    this.nStage=-4;
    document.getElementById('gameTime').innerText = aDuration;
    document.getElementById('share').style.display = "none";
    PlaySound(ad_countDown,"BellCelebrateHorn.mp3",false);
    logIt("in endGameProcess, setting good job");
    setComment(timeLapsed,"Well Done!Good Job!");

  }

}

class skaterObj{
  constructor(aCanvasWidth,aCanvasHeight){

    this.nScore=0;
    this.n_bannerY=-1;

    this.nBLlha=0;
    this.nBLlka=0;
    this.nBLrha=0;
    this.nBLrka=0;

    //this.bMoveTree=false;
    this.ln_lastSpawn = 0;
    this.arryShow = [];

    this.oTree=null;
    
    this.nGameStartTime=0;

    this.oSelector = new selectionObj(aCanvasWidth,["play 1 minute","play 3 minutes","play 5 minutes","play continuous"]
    ,["F_1min.png","F_3min.png","F_5min.png","F_unlimited.png"],[60,180,300,99999]);

    this.nStage = -3;
    this.nGameDuration=-1;

    //this.nStage = 1;
    //this.nGameDuration=99999;

  }
//skaterShow
  show(aTimeLapsed){

    if (this.nStage>0){

      if(Math.round( aTimeLapsed - this.nGameStartTime)<this.nGameDuration){

        document.getElementById('gameTime').innerText =  Math.round( aTimeLapsed - this.nGameStartTime);
        
        if (aTimeLapsed-this.ln_lastSpawn > 1){
  
          this.ln_lastSpawn = aTimeLapsed;

          for (var i=0; i < 10; i++){
  
            if (generateRandomIntegerInRange(0,9)<2)
            this.arryShow.push(new DropObj(i));
  
          }    
          
        }

        if (b_moveTree){
          logIt("moving tree");
 
          this.oTree.moveTree();
          //this.bMoveTree=false;

        }
        //camera.drawPic("xt.png",{x:0,y:200},300,400);
        var arryTmp =[];
        if (this.arryShow.length>0){

          for (var j=0; j < this.arryShow.length; j++){

            if (this.arryShow[j].dropIfValid()){

              arryTmp.push(this.arryShow[j]);

            }

          }

          this.arryShow = arryTmp;
        }

      }else if (Math.round( aTimeLapsed - this.nGameStartTime)>=this.nGameDuration && document.getElementById('gameTime').innerText!==this.nGameDuration){

        this.endGameProcess(this.nGameDuration);
        
      }
       
    }else if(this.nStage===0){

      if (document.getElementById('loading').style.display!=="none"){
        document.getElementById('loading').style.display = "none";
        document.getElementById('loadingStats').style.display = "none";
        document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
      }
      
      var w = 220, h=360;

      //this.nGameStartTime = aTimeLapsed;
      setComment(aTimeLapsed,"Feet wider than shoulder. Bend knees and lean forward. Bend your arms");
      //setComment("Feet wider than shoulder. Bend knees and lean forward. Bend your arms");
      creImage("skaterStart2.PNG",camera.canvas.width/2 - w/2,camera.canvas.height/3 - h/3, w,h);

    }else if (this.nStage===-3){

      if (document.getElementById('loading').style.display!=="none"){
        document.getElementById('loading').style.display = "none";
        document.getElementById('loadingStats').style.display = "none";
        document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
      }
      this.oSelector.showSelection();

    }

  }

  assess(apl, aTimeLapsed,aar_prevPts){

    if (this.nStage>0 ){
   
      var bContinue = this.oSelector.checkContinue(apl);

      if (bContinue){

        if (this.nStage===1){ // skate left

         
          if (getSignedANGXY(apl[26],apl[28])- this.nBLrka<-10            
          && getSignedANGXY(apl[24],apl[28])-this.nBLrha <-10
          && getSignedANGXY(apl[25],apl[27])-this.nBLlka<-10 
          && apl[11].x> apl[15].x && apl[16].x> apl[12].x && apl[13].x> apl[15].x && apl[16].x> apl[14].x
          && apl[13].y> apl[15].y && apl[14].y> apl[16].y){
    
            logIt("person left");
    
            b_moveTree=true;
            this.nStage = 2;
            this.oTree = new TreeObj(0);
            PlaySound(ad_shuttle,"SwooshLouder.mp3",false);
    
          }
    
        }else if (this.nStage===2){ // go right
    
          if (getSignedANGXY(apl[26],apl[28])- this.nBLrka>10             
          && getSignedANGXY(apl[23],apl[27])-this.nBLlha >10
          && getSignedANGXY(apl[25],apl[27])-this.nBLlka>10 
          && apl[11].x> apl[15].x && apl[16].x> apl[12].x && apl[13].x> apl[15].x && apl[16].x> apl[14].x
          && apl[13].y> apl[15].y && apl[14].y> apl[16].y ){
    
            logIt("person right");
    
            b_moveTree=true;
            this.nStage = 1;
            this.oTree = new TreeObj(1);
            PlaySound(ad_shuttle,"SwooshLouder.mp3",false);

          }
    
        }
 
      }else{

        this.endGameProcess(Math.round( aTimeLapsed - this.nGameStartTime));

      }
    }else if (this.nStage===0){

      if((apl[27].x - apl[28].x)> (apl[11].x - apl[12].x) 
      && apl[11].x > apl[15].x && apl[16].x > apl[12].x && getSignedANGXY(apl[25],apl[27])<20 && getSignedANGXY(apl[26],apl[28])>-20
      && apl[13].y > apl[15].y && apl[14].y > apl[12].x 
      ){
        
        this.nBLlha=getSignedANGXY(apl[23],apl[27]);
        this.nBLlka=getSignedANGXY(apl[25],apl[27]);
        this.nBLrha=getSignedANGXY(apl[24],apl[28]);
        this.nBLrka=getSignedANGXY(apl[26],apl[28]);

        this.nStage = 1;
        document.getElementById('TimeBar').style.display = "block";
        //document.getElementById('scoreCount').style.display = "block";
                    
        //document.getElementById('send').style.display = "block";
        this.nGameStartTime = aTimeLapsed;
        instructNow("Skate to your left.");
        logIt("set Snow true and gameTIme at " +  this.nGameStartTime);

      }

    }else if (this.nStage===-3){

      this.nGameDuration = this.oSelector.assessSelection(apl);

      if (this.nGameDuration>0){
        
        this.nStage = 0;
      }

    }

    this.n_bannerY= (apl[11].y>apl[12].y)?apl[11].y:apl[12].y;

  }

  endGameProcess(aDuration){

//    if (n_scoreCount>0){
//      UpdateReward();
 //   }
    //document.getElementById('gameTime').innerText = aDuration;
    b_inEx=false;
    PlaySound(ad_countDown,"BellCelebrateHorn.mp3",false);
    setComment(timeLapsed,"You've done it!Good Job!");

    
  }


}

class clockObj{
  constructor(aCanvasWidth, aCanvasHeight){

    this.time = 45;
    this.dimWidth = 240;
    this.dimHeight = 225;

    this.width = 0.25*aCanvasWidth;
    this.height = this.dimHeight*this.width/this.dimWidth;

    this.x = aCanvasWidth/2 - this.width/2;
    this.y = 0;

    this.dimWidthStar = 280;
    this.dimHeightStar = 250;


    this.dimWidthSanta = 750;
    this.dimHeightSanta = 750;

    this.heightSanta = 0.8 * aCanvasHeight;
    //this.widthSanta = this.dimWidthSanta*this.heightSanta/this.dimHeightSanta;
    this.widthSanta= 0.8 *aCanvasWidth;
   
    this.widthStar = 0.2*aCanvasWidth;
    this.heightStar = this.dimHeightStar*this.widthStar/this.dimWidthStar;

    this.rX = generateRandomIntegerInRange(0,1);
    this.lX = generateRandomIntegerInRange(7,9);

    this.rY = generateRandomIntegerInRange(0,3);
    this.lY = generateRandomIntegerInRange(0,3);

    this.glitterStars = [];
    this.alarmTime = -1;

    this.diameterPendulum = -1;
    this.pendulumX = -1;
    this.pendulumY =-1;
//bob dimension is 1:1 no need to cal height


    this.n_bannerY = 0;
    this.side = "Left";


    this.oSantaSleigh = new santaSleighObj(aCanvasWidth, aCanvasHeight,this.height,timeLapsed)
    this.nScore=0;
    this.getNewPosition(false);
    this.nGameStartTime=0;

    this.oSelector = new selectionObj(aCanvasWidth,["play 1 minute","play 3 minutes","play 5 minutes","play continuous"]
    ,["F_1min.png","F_3min.png","F_5min.png","F_unlimited.png"],[60,180,300,99999]);

    /*


        this.nStage = 1;
    this.nGameDuration=99999;
    */


    this.nStage = -3;
    this.nGameDuration=-1;


  }

  getNewPosition(b_progress){

    this.rX = generateRandomIntegerInRange(0,1);
    this.lX = generateRandomIntegerInRange(3,4);

    this.rY = generateRandomIntegerInRange(0,3);
    this.lY = generateRandomIntegerInRange(0,3);

    if (b_progress)
      this.progressTime();

  }

  progressTime(){
    
    this.time += 5;
    
  }

  resetTime(){
    this.time = 45;
  }

  //clock.show
  show(aTimeLapsed){

    if (this.nStage>0){

      document.getElementById('gameTime').innerText =  Math.round( aTimeLapsed - this.nGameStartTime);

      logIt(" Pendulum diameter:" +this.diameterPendulum +" with X:" + this.pendulumX + " and Y:" + this.pendulumY);
      if (this.diameterPendulum>0){

        creImage("T_bob.png",this.pendulumX,this.pendulumY,this.diameterPendulum,this.diameterPendulum );

      }

      if (this.time===60){

        if (this.glitterStars.length ===0){
  
          this.nScore++
          //n_scoreCount++;
          //document.getElementById('scoreCount').innerText = this.nScore;
  
          this.glitterStars.push({x:0,y:0});
          this.glitterStars.push({x:0,y:1});
          this.glitterStars.push({x:0,y:2});
  
          this.glitterStars.push({x:4,y:0});
          this.glitterStars.push({x:4,y:1});
          this.glitterStars.push({x:4,y:2});
  
  
        }
  
        if (this.alarmTime === -1){
  
          this.alarmTime = aTimeLapsed;
          PlaySound(ad_shuttle,"wah.mp3");
  
        }
  
        if (aTimeLapsed - this.alarmTime <2.5){
  
          creImage("TF_cSC.png",camera.canvas.width/2-exObj.widthSanta/2,0.1*camera.canvas.height,exObj.widthSanta,exObj.heightSanta );
          for (var i =0; i< this.glitterStars.length;i++ ){

            var sFileName="T_starGlow.png";

  
            if (aTimeLapsed - this.alarmTime > 1.9){
              creImage(sFileName,this.glitterStars[i].x*this.widthStar
              ,this.glitterStars[i].y*this.heightStar,this.widthStar,this.heightStar );
            }else if (aTimeLapsed - this.alarmTime > 1.6){
              creImage("T_star.png",this.glitterStars[i].x*this.widthStar
              ,this.glitterStars[i].y*this.heightStar,this.widthStar,this.heightStar );
            }else if (aTimeLapsed - this.alarmTime > 1.2){
              creImage(sFileName,this.glitterStars[i].x*this.widthStar
              ,this.glitterStars[i].y*this.heightStar,this.widthStar,this.heightStar );
            }else if (aTimeLapsed - this.alarmTime > 0.8){
              
              creImage("T_star.png",this.glitterStars[i].x*this.widthStar
              ,this.glitterStars[i].y*this.heightStar,this.widthStar,this.heightStar );
  
            }else if (aTimeLapsed - this.alarmTime > 0.4){
              creImage(sFileName,this.glitterStars[i].x*this.widthStar
              ,this.glitterStars[i].y*this.heightStar,this.widthStar,this.heightStar );
            }else{
  
              creImage("T_star.png",this.glitterStars[i].x*this.widthStar
              ,this.glitterStars[i].y*this.heightStar,this.widthStar,this.heightStar );
  
            }
  
          }
  
        }else{
  
          this.alarmTime = -1;
          this.glitterStars =[];
          this.time = 45;

          /*
          if ((aTimeLapsed - this.nGameStartTime) > this.nGameDuration/2 && this.side==="Left" ){
          //if (false){
            this.side = "Right";
            s_comment = "Now change to the " + this.side;
          }else 
          */
          
          if ((aTimeLapsed - this.nGameStartTime) > this.nGameDuration) {
  
            this.endGameProcess();
  
          }else{

            if (this.side==="Left"){
              this.side="Right";
            }else{
  
              this.side="Left";
  
            }
  
            s_comment = "Change to the " + this.side;

          }
          
          //this.getNewPosition(false);
         
        }
  
      }else{
  
  
        creImage("TF_" +this.time+"CF.png",this.x,this.y,this.width,this.height );
        creImage("T_star.png",this.rX*this.widthStar,this.rY*this.heightStar,this.widthStar,this.heightStar );
        creImage("T_star.png",this.lX*this.widthStar,this.lY*this.heightStar,this.widthStar,this.heightStar );
        this.oSantaSleigh.show(aTimeLapsed);
      }
     
    } // start animation only after ExP
    else if(this.nStage===0){

      if (document.getElementById('loading').style.display!=="none"){
        document.getElementById('loading').style.display = "none";
        document.getElementById('loadingStats').style.display = "none";
        document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
      }

      
      var w = 160, h=330;

      //this.nGameStartTime = aTimeLapsed;
      //instruct("Feet wider than shoulder. Bend knees and lean forward. Bend your arms");
      creImage("clockStart2.PNG",camera.canvas.width/2 - w/2,camera.canvas.height/3 - h/3, w,h);
    } else if (this.nStage===-3){

      if (document.getElementById('loading').style.display!=="none"){
        document.getElementById('loading').style.display = "none";
        document.getElementById('loadingStats').style.display = "none";
        document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
      }
      this.oSelector.showSelection();

    }


  }

  /*
  setComment(aTimeLapsed, aCommentStr){

    if(aTimeLapsed - this.lastCommentTime > ln_configRA || this.prevComment!==aCommentStr){

      this.comment = aCommentStr;

    }

  }
  */

  endGameProcess(){
    
//    if (this.nScore>0)
//      UpdateReward();
    b_inEx=false;
    PlaySound(ad_countDown,"BellCelebrateHorn.mp3",false);   
    setComment(timeLapsed,"You've done it!Good Job!");      


  }

  assess(apl, aTimeLapsed,aar_prevPts){

    if (this.nStage>0){

      this.n_bannerY = (apl[11].y>apl[12].y)?apl[11].y:apl[12].y;
      var bContinue = this.oSelector.checkContinue(apl);

      if (bContinue){

        if (this.nStage===1){

          if (this.time === 45){
    
            setComment(aTimeLapsed, "Swing your " + this.side + " leg to the front.");
          }else if(this.time === 50){
    
            setComment(aTimeLapsed, "Swing your " + this.side + " leg to the side.");
    
          }else if(this.time === 55){
    
            setComment(aTimeLapsed, "Swing your " + this.side + " leg to the back.");
    
          }
    
          if (this.side==="Left"){
    
            if (apl[31].y < apl[28].y){
              
              logIt("checking diameter: " + this.diameterPendulum + " curr: " + ((apl[31].y - apl[27].y)*4));
              this.pendulumX = apl[27].x - this.diameterPendulum/2;
              this.pendulumY = apl[27].y- this.diameterPendulum/2;
              if (this.diameterPendulum ===-1){
                this.diameterPendulum = (apl[31].y - apl[27].y)*4;
    
              }else if (this.diameterPendulum < (apl[31].y - apl[27].y)*4){
                this.diameterPendulum = (apl[31].y - apl[27].y)*4;
    
              }
    
    
            }else{
    
              if (this.diameterPendulum>0){
    
                PlaySound(ad_tick,"clock-ticking3.mp3",false);
                this.getNewPosition(true);
    
              }
    
              this.diameterPendulum = -1;
            }
    
          }else if(this.side==="Right"){
    
            if (apl[32].y < apl[27].y){
              
              logIt("checking diameter: " + this.diameterPendulum + " curr: " + ((apl[32].y - apl[28].y)*4));
              this.pendulumX = apl[28].x - this.diameterPendulum/2;
              this.pendulumY = apl[28].y - this.diameterPendulum/2 ;
              if (this.diameterPendulum ===-1){
                this.diameterPendulum = (apl[32].y - apl[28].y)*4;
    
              }else if (this.diameterPendulum < (apl[32].y - apl[28].y)*4){
                this.diameterPendulum = (apl[32].y - apl[28].y)*4;
    
              }
    
    
            }else{
    
              if (this.diameterPendulum>0){
    
                PlaySound(ad_tick,"clock-ticking3.mp3",false);
                this.getNewPosition(true);
    
              }
    
              this.diameterPendulum = -1;
            }
    
          }
    
    
        }
  
      }else{

        this.endGameProcess();

      }

    }else if (this.nStage===0){

      // check for ANG Lebw<-10, Rebw>10, Lka<15 Rka >-15 

      if(getSignedANGXY(apl[13],apl[15])<-10 && getSignedANGXY(apl[14],apl[16])>10 
      && getSignedANGXY(apl[25],apl[27])<15 && getSignedANGXY(apl[26],apl[28])>-15
      && checkStdev(aar_prevPts,15,1.5) && checkStdev(aar_prevPts,16,1.5)
      && checkStdev(aar_prevPts,27,1.5) && checkStdev(aar_prevPts,28,1.5)     
      ){

        this.nStage=1;
        this.nGameStartTime = aTimeLapsed;

        document.getElementById('gameTime').innerText =  Math.round( aTimeLapsed - this.nGameStartTime);

      }else{

        setComment(aTimeLapsed,"Stand straight. Hands on your hips");

      }

    }else if (this.nStage===-3){

      this.nGameDuration = this.oSelector.assessSelection(apl)
      if (this.nGameDuration>0){
        
        this.nStage = 0;
      }

    }

  }

  /*
  readComment(aTimeLapsed){

    if (this.comment.length>0){

      instructNow(this.comment);
      this.prevComment = this.comment;
      this.lastCommentTime = aTimeLapsed;

      this.comment = "";
     

    }

  }

  */


}

class santaSleighObj{
  constructor(aCanvasWidth,aCanvasHeight, aclockHeight, aTimeLapsed){

    this.nCanvasWidth = aCanvasWidth;
    this.nDimSantaWidth = 2;
    this.nDimSantaHeight = 1;
 


    this.nSantaHeight = 0.25*aCanvasHeight;
    this.nSantaWidth = this.nSantaHeight* this.nDimSantaWidth/this.nDimSantaHeight;
    this.nDisplacementX= aCanvasWidth/14;

    this.nSide = 1;

    this.nHeightDisplacement = aclockHeight/2;
    this.nY = aclockHeight;

    this.nAnimateTime = aTimeLapsed+1;

  }

  show(aTimeLapsed){

    if (aTimeLapsed - this.nAnimateTime > 0){

      var nMoveUnit = Math.round((aTimeLapsed - this.nAnimateTime)/0.25);

      if (nMoveUnit < 20){

        if (this.nSide===1){

          creImage("T_rLCSCoins"+nMoveUnit%2 +".png",nMoveUnit*this.nDisplacementX, this.nY, this.nSantaWidth, this.nSantaHeight);

        }else{

          creImage("T_lRCSCoins"+nMoveUnit%2 +".png",this.nCanvasWidth - (nMoveUnit+2)*this.nDisplacementX, this.nY, this.nSantaWidth, this.nSantaHeight);

        }

      }else{
        this.nAnimateTime = aTimeLapsed + generateRandomIntegerInRange(1,2);
        this.nY = generateRandomIntegerInRange(1,4)*  this.nHeightDisplacement;
        this.nSide = -1* this.nSide;

      }


    }

  }


}

class fairyObj{
  constructor(aCanvasWidth,aCanvasHeight){

    this.nDimFairy1Width = 650;
    this.nDimFairy1Height = 480;

    /*

    this.nFairy1Height = 0.3 *aCanvasHeight;
    this.nFairyWidth = this.nFairy1Height * this.nDimFairy1Width/this.nDimFairy1Height;

    */
    
    this.nFairyWidth = 0.4*aCanvasWidth;


    this.nFairy1Height = this.nDimFairy1Height * this.nFairyWidth/this.nDimFairy1Width;

    this.nFairy0Height = 0.9*this.nFairy1Height;
    
    this.nWingState=0;
    this.nWingFlapTime =-1;

  }

  show(ax,ay, aIndex, aTimeLapsed)
  {
    if (aIndex===15){

      if (aTimeLapsed - this.nWingFlapTime> 0.4){
        this.nWingFlapTime = aTimeLapsed;
        if (this.nWingState===0){
          this.nWingState=1;
        }else{

          this.nWingState=0;

        }

      }

      if (this.nWingState===0){
        creImage("T_lcf0.png",ax,ay,this.nFairyWidth,this.nFairy0Height);
      }else{
        creImage("T_lcf1.png",ax,ay,this.nFairyWidth,this.nFairy1Height);
      }
      
    }else if (aIndex===16){

      if (aTimeLapsed - this.nWingFlapTime> 0.4){
        this.nWingFlapTime = aTimeLapsed;
        if (this.nWingState===0){
          this.nWingState=1;
        }else{

          this.nWingState=0;

        }

      }

      if (this.nWingState===0){
        creImage("T_rcf0.png",ax,ay,this.nFairyWidth,this.nFairy0Height);
      }else{
        creImage("T_rcf1.png",ax,ay,this.nFairyWidth,this.nFairy1Height);
      }
      
    }



  }

}

class candyObj{
  constructor(aCanvasWidth,aCanvasHeight){

    //this.sWarpDirection = "Left to Right ";
    this.nDimCandyWidth=100;
    this.nDimCandyHeight=340;

    /*
    this.nCandyWidth = aCanvasWidth/4;
    this.nCandyHeight = this.nDimCandyHeight * this.nCandyWidth/this.nDimCandyWidth;
    */

    this.nCandyWidth = aCanvasWidth/4;
    this.nCandyHeight = 0.6*aCanvasHeight;

    this.nCandyWidth = this.nDimCandyWidth * this.nCandyHeight/this.nDimCandyHeight;

    this.x = 0;
    this.y = (aCanvasHeight - this.nCandyHeight)/2;

    this.sColorDirection = "T";
    this.nRedCount=0;
    this.sComment = "";
    this.sPrevComment = "";
    this.nLastCommentTime = 0;

    this.nMaxHeight = aCanvasHeight;
    this.nMaxWidth = this.nMaxHeight*this.nDimCandyWidth/this.nDimCandyHeight;

    this.nAnimationTransitionTime=0.2;
    this.nAnimationLapTime=1;
    this.nAnimationSteps = this.nAnimationLapTime/this.nAnimationTransitionTime;

    this.nGrowthRate = (this.nMaxHeight - this.nCandyHeight)/this.nAnimationSteps;
    this.nMaxHeightX = aCanvasWidth/2 -this.nMaxWidth/2;

    this.nMoveRate = this.nMaxHeightX/this.nAnimationSteps;
    this.nAnimationStartTime=-1;

    this.nPresentHeight = aCanvasHeight/5; // for Xmas theme
    this.nPresentWidth = aCanvasHeight/5;

    this.nPresentX = aCanvasWidth - this.nPresentWidth;
    this.nPresentY = aCanvasHeight - this.nPresentHeight;

    this.oPl = null;

//    this.arryOptionText = ["1 minute","3 minute","5 minute","unlimited"];
//    this.arryOptionIcon = ["1min.png","3min.png","5min.png","unlimited.png"];

    this.oSelector = new selectionObj(aCanvasWidth,["play 1 minute","play 3 minutes","play 5 minutes","play continuous"]
    ,["F_1min.png","F_3min.png","F_5min.png","F_unlimited.png"],[60,180,300,99999]);

    this.nStage = -3;
    this.nGameDuration=-1;

    /*
    
        this.nStage = 1;
    this.nGameDuration=99999;

    this.nStage = -3;
    this.nGameDuration=-1;
    
    this.nDimFairyHeight = 25;
    this.nDimFairyWidth = 21;

    this.nFairyHeight = 0.3 * aCanvasHeight;
    this.nFairyWidth = this.nFairyHeight * this.nDimFairyWidth/this.nDimFairyHeight;
    
    */

    this.nFairyIndex=15;
    this.nFairyX=-1;
    this.nFairyY=-1;
    this.oFairy = new fairyObj(aCanvasWidth,aCanvasHeight);



    this.nRefShoulderWidth =-1;

    this.nGameStartTime=0;

    this.nHandClosedThreshold=0.55;
    this.nHandOpenedThreshold=0.6;
    this.bAssess=true;

    this.nEndCloseTarget = 3;
    this.nEndCloseCount=0;
    this.nScore=0;
    this.nAddColorTime = -1;

    this.n_bannerY=-1;

  }

  toggleCandy(){
    this.nRedCount=0;


   this.bAssess=true;
    this.nAnimationStartTime =-1;
  }

  getShrinkRate(){

    this.nPresentHeight = ((this.oPl[24].y - this.oPl[12].y) + (this.oPl[23].y - this.oPl[11].y))/2;
    this.nPresentWidth = this.oPl[11].x - this.oPl[12].x;
    this.nPresentX = this.oPl[12].x;

    this.nShrinkRate = (this.nMaxHeight - this.nPresentHeight)/(this.nAnimationSteps-1);
    this.nMinHeightX = (this.nPresentX + this.nPresentWidth/2  - (this.nPresentHeight*this.nDimCandyWidth/this.nDimCandyHeight)/2);

    this.nShrinkMoveRate = (this.nMinHeightX - this.nMaxHeightX )/(this.nAnimationSteps-1);

  }

  //candyShow
  show(aTimeLapsed){
   
    if (this.nStage>0){

      //scoreImg
     
      document.getElementById('gameTime').innerText =  Math.round( aTimeLapsed - this.nGameStartTime);      

//      creImage("T_presentGold.png",this.nPresentX,this.nPresentY,this.nPresentWidth,this.nPresentHeight);


      //var s_imageName = "T_cc" + this.sColorDirection + this.nRedCount + "R.png";
      var s_imageName = "T_fb" + this.nRedCount + ".png";

      if (this.nRedCount===0){
        //s_imageName = "T_cc0R.png";
        s_imageName = "T_fb0.png";
      }else if(this.nRedCount===4){

        s_imageName = "T_fb4.png";

      }

      if (this.nRedCount===4){

        
        logIt("in Red count 4");
        if (this.nAnimationStartTime===-1){
          this.nAnimationStartTime = aTimeLapsed;
          
        }

        if (aTimeLapsed - this.nAnimationStartTime>2.7){

          logIt("this.nAnimationStartTime>2");
          this.toggleCandy();
          //creImage("T_cc0R.png",this.x,this.y,this.nCandyWidth,this.nCandyHeight);
          creImage("T_fb0.png",this.x,this.y,this.nCandyWidth,this.nCandyHeight);

          if (this.sColorDirection==="B"){
            setComment(timeLapsed," Right to left. Round Knee.");
          }else{
            setComment(timeLapsed," Left to right. Round head.");
          }        

        }else if (aTimeLapsed - this.nAnimationStartTime>2.2){

          logIt("this.nAnimationStartTime>1.8");
          creImage(s_imageName,this.nMaxHeightX + 4*this.nShrinkMoveRate, 4*this.nShrinkRate,(this.nMaxHeight - 4*this.nShrinkRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nMaxHeight - 4*this.nShrinkRate);
        }else if (aTimeLapsed - this.nAnimationStartTime>1.9){

          logIt("this.nAnimationStartTime>1.6");
          creImage(s_imageName,this.nMaxHeightX + 3*this.nShrinkMoveRate, 3*this.nShrinkRate,(this.nMaxHeight - 3*this.nShrinkRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nMaxHeight - 3*this.nShrinkRate);
        }else if (aTimeLapsed - this.nAnimationStartTime>1.6){

          logIt("this.nAnimationStartTime>1.4");
          creImage(s_imageName,this.nMaxHeightX + 2*this.nShrinkMoveRate, 2*this.nShrinkRate,(this.nMaxHeight - 2*this.nShrinkRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nMaxHeight - 2*this.nShrinkRate);
        }else if (aTimeLapsed - this.nAnimationStartTime>1.3){

          logIt("this.nAnimationStartTime>1.2");
          creImage(s_imageName,this.nMaxHeightX + this.nShrinkMoveRate, this.nShrinkRate,(this.nMaxHeight - this.nShrinkRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nMaxHeight - this.nShrinkRate);

        }else if (aTimeLapsed - this.nAnimationStartTime>1){

          logIt("this.nAnimationStartTime>1");

          creImage(s_imageName,this.x + 5* this.nMoveRate,0,(this.nCandyHeight + 5*this.nGrowthRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nCandyHeight + 5*this.nGrowthRate);
          this.getShrinkRate();

        }else if (aTimeLapsed - this.nAnimationStartTime>0.8){

          logIt("this.nAnimationStartTime>0.8");
          creImage(s_imageName,this.x + 4* this.nMoveRate,this.y - 4*this.nGrowthRate/2,(this.nCandyHeight + 4*this.nGrowthRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nCandyHeight + 4*this.nGrowthRate);

        }else if (aTimeLapsed - this.nAnimationStartTime>0.6){

          logIt("this.nAnimationStartTime>0.6");
          creImage(s_imageName,this.x + 3* this.nMoveRate,this.y - 3*this.nGrowthRate/2,(this.nCandyHeight + 3*this.nGrowthRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nCandyHeight + 3*this.nGrowthRate);

        }else if (aTimeLapsed - this.nAnimationStartTime>0.4){

          logIt("this.nAnimationStartTime>0.4");
          creImage(s_imageName,this.x + 2* this.nMoveRate,this.y - 2*this.nGrowthRate/2,(this.nCandyHeight + 2*this.nGrowthRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nCandyHeight + 2*this.nGrowthRate);

        }else if (aTimeLapsed - this.nAnimationStartTime>0.2){

          logIt("this.nAnimationStartTime>0.2");
          creImage(s_imageName,this.x + this.nMoveRate,this.y -this.nGrowthRate/2,(this.nCandyHeight + this.nGrowthRate) *this.nDimCandyWidth/this.nDimCandyHeight,this.nCandyHeight + this.nGrowthRate);

        }else if (aTimeLapsed - this.nAnimationStartTime<0.2){

          logIt("this.nAnimationStartTime<0.2");
          creImage(s_imageName,this.x,this.y,this.nCandyWidth,this.nCandyHeight);

        }

      }else{

        logIt("red not 4 display this: " + s_imageName 
        + " this.x: " +this.x+" this.y: "+ this.y+" this.nCandyWidth: " +this.nCandyWidth + " this.nCandyHeight: " +this.nCandyHeight);

        creImage(s_imageName,this.x,this.y,this.nCandyWidth,this.nCandyHeight);

        if(aTimeLapsed - this.nAddColorTime <0.7){

          //creImage("T_lFairy.png", this.x + this.nCandyWidth,this.y,this.nFairyWidth,this.nFairyHeight);
          this.oFairy.show(this.x + this.nCandyWidth,this.y,15,aTimeLapsed);
        }else{

          this.oFairy.show(this.nFairyX,this.nFairyY,this.nFairyIndex,aTimeLapsed);


        }       

      }

     
    } // start animation only after ExP
    else if(this.nStage===0){

      if (document.getElementById('loading').style.display!=="none"){
        document.getElementById('loading').style.display = "none";
        document.getElementById('loadingStats').style.display = "none";
        document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
      }
     
      var w = 145, h=400;
      //var w = 35, h=100;

      //this.nGameStartTime = aTimeLapsed;
      setComment(aTimeLapsed,"Clasp a thin object. In front of chest.");
      creImage("candyStart2.PNG",camera.canvas.width/2 - w/2,camera.canvas.height/3 - h/3, w,h);
    } 
    else if (this.nStage===-3){

      if (document.getElementById('loading').style.display!=="none"){
        document.getElementById('loading').style.display = "none";
        document.getElementById('loadingStats').style.display = "none";
        document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
      }
      this.oSelector.showSelection();

    }

  }

  addColor(){

    if (this.nRedCount < 4){

     
      this.nAddColorTime = timeLapsed;
      this.nRedCount++;
      if (this.nRedCount < 4)
        PlaySound(ad_shuttle,"magicBell.mp3");
    }
    
    logIt("added color red now: " + this.nRedCount);
  }

  endGameProcess(aShoulderY){
    //this.nScore++;

//    if (this.nScore>0)
//      UpdateReward();

    //document.getElementById('scoreCount').innerText = this.nScore;
    logIt("banner height at:" + aShoulderY);
    this.n_bannerY = aShoulderY;
    b_inEx=false;
    PlaySound(ad_countDown,"BellCelebrateHorn.mp3",false);
    setComment(timeLapsed,"You've done it!Good Job!");
    //s_comment = "You've done it!Good Job!";
    
  }

// candyAssess
  assess(apl, aTimeLapsed,aar_prevPts){

    this.oPl = apl;

    if (this.bAssess){

      var nFingerWidth = apl[19].x - apl[20].x;
      var nMidSH = (apl[12].y+apl[23].y)/2;
      var nShoulderWidth = apl[11].x - apl[12].x;
      var nElbowWidth = apl[13].x - apl[14].x;
      var nHipWidth =apl[23].x - apl[24].x;
      var nKneeWidth = apl[25].x - apl[26].x;

      this.nFairyY = (apl[5].y +apl[2].y)/2 - this.oFairy.nFairy1Height;

      if (this.nFairyY<0)
        this.nFairyY=0;
      
      logIt(this.nStage+": stage  nFingerWidth: " + nFingerWidth + " this.nRefShoulderWidth: " 
      + this.nRefShoulderWidth +" nFingerWidth/this.nRefShoulderWidth :"+(nFingerWidth/this.nRefShoulderWidth)+ " in stage:" + this.nStage);
      logIt("nMidSH:" + nMidSH + " apl[16].y:" + apl[16].y + " apl[15].y:" + apl[15].y);
  
      logIt("nFingerWidth: " + nFingerWidth + " nHipWidth: " + nHipWidth );
      logIt("nFingerWidth: " + nFingerWidth + " nKneeWidth: " + nKneeWidth );

      if (this.nStage>0 ){
   
        var bContinue = this.oSelector.checkContinue(apl);

        if (bContinue){

          if (this.nStage===1){
    
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth >  this.nRefShoulderWidth*this.nHandClosedThreshold && apl[15].y < nMidSH && apl[16].y < nMidSH){
      
              
              if (this.nEndCloseCount < this.nEndCloseTarget){
  
                this.nEndCloseCount++;
                logIt(this.nStage + ": stage endClose count: " + this.nEndCloseCount);
  
              }else{
  
                //PlaySound(ad_shuttle,"yummy2.mp3");
                PlaySound(ad_shuttle,"magicLong.mp3");
                this.nEndCloseCount=0
                this.addColor();
                logIt("adding color:" + this.nStage);
                //s_comment="Excellent.";
        
                if (Math.round(aTimeLapsed-this.nGameStartTime) < this.nGameDuration){
        
                  this.nScore++;
                  //document.getElementById('scoreCount').innerText = this.nScore;
                  this.sColorDirection = "T";
                  this.bAssess=false;
                  this.nStage=2;
                  //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                  this.nFairyIndex=15;
                  logIt(this.nStage+": stage increment score:" + this.nScore);
        
                }else{
                  this.endGameProcess((apl[11].y>apl[12].y)?apl[11].y:apl[12].y);
                }
                
                logIt("hands opened going nStage:" + this.nStage);
  
              }
  
      
            }else{
              this.nEndCloseCount=0;
            }
      
          }
          else if (this.nStage===2){
      
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth >  this.nRefShoulderWidth*this.nHandOpenedThreshold 
              && apl[15].y < apl[11].y&& apl[16].y < apl[12].y){
      
              if (this.sColorDirection === "B"){
                
                this.nStage=1;
                this.nFairyIndex=15;
                //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                this.nFairyX = apl[this.nFairyIndex].x;
              
              }else{
                        
                this.nStage=3;
                //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                this.nFairyIndex=16;
                this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
              }
              
      
              logIt(this.sColorDirection +" hands opened going nStage:" + this.nStage);
      
            }
      
          }
          else if (this.nStage===3){
  
  
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth <  this.nRefShoulderWidth*this.nHandClosedThreshold 
              && apl[15].y < apl[11].y&& apl[16].y < apl[12].y){
      
  
                if (this.nEndCloseCount < this.nEndCloseTarget){
  
                  this.nEndCloseCount++;
                  logIt(this.nStage + ": stage endClose count: " + this.nEndCloseCount);
      
                }else{
                  this.nEndCloseCount=0;
                  logIt("adding color:" + this.nStage);
                  //this.addColor();
          
                  if (this.sColorDirection === "B"){
                    setComment(timeLapsed,"Good. Round Head.");
                    this.nStage=2;
                    //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                    this.nFairyIndex=16;
                    this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
                  
                  }else{
                    
                    setComment(timeLapsed,"Good. Round Chest.");
                    this.nStage=4;
                    //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                    this.nFairyIndex=15;
                    this.nFairyX = apl[this.nFairyIndex].x;
  
                  }
          
                  logIt(this.sColorDirection +" hands closed going nStage:" + this.nStage);
  
  
                }
      
            }else{
  
              this.nEndCloseCount=0;
  
            }
      
          }
  
          else if (this.nStage===4){
      
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth >  this.nRefShoulderWidth*this.nHandOpenedThreshold 
              && apl[15].y < apl[11].y&& apl[16].y < apl[12].y){
      
              if (this.sColorDirection === "B"){
                
                this.nStage=3;
                this.nFairyIndex=15;
                //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                this.nFairyX = apl[this.nFairyIndex].x;
              
              }else{
                        
                this.nStage=5;
                //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                this.nFairyIndex=16;
                this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
              }
              
      
              logIt(this.sColorDirection +" hands opened going nStage:" + this.nStage);
      
            }
      
          }
          else if (this.nStage===5){
      
  
  
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth <  this.nRefShoulderWidth*this.nHandClosedThreshold 
              && apl[16].y < nMidSH && apl[15].y < nMidSH){
      
  
                if (this.nEndCloseCount < this.nEndCloseTarget){
  
                  this.nEndCloseCount++;
                  logIt(this.nStage + ": stage endClose count: " + this.nEndCloseCount);
      
                }else{
                  this.nEndCloseCount=0;
                  logIt("adding color:" + this.nStage);
                  this.addColor();
          
                  if (this.sColorDirection === "B"){
                    setComment(timeLapsed,"Good. Round Head.");
                    this.nStage=2;
                    //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                    this.nFairyIndex=16;
                    this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
                  
                  }else{
                    
                    setComment(timeLapsed,"Good. Round Chest.");
                    this.nStage=6;
                    //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                    this.nFairyIndex=15;
                    this.nFairyX = apl[this.nFairyIndex].x;
  
                  }
          
                  logIt(this.sColorDirection +" hands closed going nStage:" + this.nStage);
  
  
                }
      
            }else{
  
              this.nEndCloseCount=0;
  
            }
      
          }
          else if (this.nStage===6){
  
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth >  this.nRefShoulderWidth*this.nHandOpenedThreshold 
              && apl[16].y < nMidSH && apl[15].y < nMidSH
              && apl[16].y > apl[0].y && apl[15].y > apl[0].y){
      
                if (this.sColorDirection === "B"){
                  this.nStage=5;
                  //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                  this.nFairyIndex=15;
                  this.nFairyX = apl[this.nFairyIndex].x;
                
                }else{
                  
                  this.nStage=7;
                  //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                  this.nFairyIndex=16;
                  this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
                }
              
                logIt(this.sColorDirection +" hands opened going nStage:" + this.nStage);
      
            }
      
          }
          else if (this.nStage===7){
  
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth <  this.nRefShoulderWidth*this.nHandClosedThreshold 
              && apl[16].y > apl[12].y && apl[15].y > apl[11].y){
      
                if (this.nEndCloseCount < this.nEndCloseTarget){
  
                  this.nEndCloseCount++;
                  logIt(this.nStage + ": stage endClose count: " + this.nEndCloseCount);
      
                }else{
  
                  this.nEndCloseCount=0;
                  logIt(this.nStage+": stage finger width and lower shoulder cleared");
      
                  if (this.sColorDirection === "B"){
          
                    if (apl[16].y > nMidSH && apl[15].y > nMidSH){
                      this.nStage=6;
                      //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                      this.nFairyIndex=16;
                      this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
                      setComment(timeLapsed,"Great. Round Chest.");
                      logIt("adding color:" + this.nStage);
                      this.addColor();
                    }else{
                      logIt(this.nStage+": height not cleared ");
                    }
          
                  }else{
          
                    if (apl[16].y < nMidSH && apl[15].y < nMidSH){
                      setComment(timeLapsed,"Lovely. Round Hip.");
                      this.nStage=8;
                      //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                      this.nFairyIndex=15;
                      this.nFairyX = apl[this.nFairyIndex].x;
                      logIt("adding color:" + this.nStage);
                      this.addColor();
                    }else{
          
                    }
          
                  }
          
                  logIt(this.sColorDirection +" hands closed going nStage:" + this.nStage);
  
  
                }
      
            }else{
              this.nEndCloseCount=0;
            }
      
          }else if (this.nStage===8){
      
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth >  nHipWidth
              && apl[16].y > nMidSH && apl[15].y > nMidSH
              && apl[16].y < apl[26].y && apl[15].y < apl[25].y){
      
                if (this.sColorDirection === "B"){
                  this.nStage=7;
                  //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                  this.nFairyIndex=15;
                  this.nFairyX = apl[this.nFairyIndex].x;
                
                }else{
                  
                  this.nStage=9;
                  //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                  this.nFairyIndex=16;
                  this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
                }
      
              
      
              logIt(this.sColorDirection +" hands opened going nStage:" + this.nStage);
      
            }
      
          }
          else if (this.nStage===9){
      
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth <  nHipWidth 
              && apl[16].y > nMidSH && apl[15].y > nMidSH
              && apl[16].y < apl[26].y && apl[15].y < apl[25].y){
      
  
                if (this.nEndCloseCount < this.nEndCloseTarget){
  
                  this.nEndCloseCount++;
                  logIt(this.nStage + ": stage endClose count: " + this.nEndCloseCount);
      
                }else{
  
                  this.nEndCloseCount =0;
                this.addColor();
                logIt("adding color:" + this.nStage);
                
        
                if (this.sColorDirection === "B"){
                  this.nStage=8;
                  //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                  this.nFairyIndex=16;
                  this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
                  setComment(timeLapsed,"Great. Round Hip.");
                }else{
                  setComment(timeLapsed,"Great. Round Knee.");
                  this.nStage=10;
                  //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                  this.nFairyIndex=15;
                  this.nFairyX = apl[this.nFairyIndex].x;
                }
        
                logIt(this.sColorDirection +" hands closed going nStage:" + this.nStage);
  
  
                }
  
  
      
            }else{
  
              this.nEndCloseCount =0;
            }
      
          }else if (this.nStage===10){
      
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
  
            if (nFingerWidth >  nKneeWidth         
              && apl[16].y > apl[24].y && apl[15].y > apl[23].y){
            
              if (this.sColorDirection === "B"){
                this.nStage=9;
                //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                this.nFairyIndex=15;
                this.nFairyX = apl[this.nFairyIndex].x;
              }
              else{
                this.nStage=11;
                //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
                this.nFairyIndex=16;
                this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
              }
            
              logIt(this.sColorDirection +" hands opened going nStage:" + this.nStage);
      
            }
      
          }    
          else if (this.nStage===11){
      
            if (this.nFairyIndex===16){
              this.nFairyX = apl[this.nFairyIndex].x- this.oFairy.nFairyWidth;
            }else{
              this.nFairyX = apl[this.nFairyIndex].x;
            }
            
  
            if (nFingerWidth <  nKneeWidth
              && apl[16].y > apl[24].y && apl[15].y > apl[23].y){
      
  
                if (this.nEndCloseCount < this.nEndCloseTarget){
  
                  this.nEndCloseCount++;
                  logIt(this.nStage + ": stage endClose count: " + this.nEndCloseCount);
      
                }else{
  
                  //PlaySound(ad_shuttle,"yummy2.mp3");
                  PlaySound(ad_shuttle,"magicLong.mp3");
                  this.nEndCloseCount =0;
                  this.addColor();
                  logIt("adding color:" + this.nStage);
                  //setComment(timeLapsed,"Wonderful.");
        
                  if (Math.round(aTimeLapsed-this.nGameStartTime) < this.nGameDuration){
        
      
                    this.nScore++;
      
                   // document.getElementById('scoreCount').innerText = this.nScore;
                    this.bAssess = false; 
                    this.sColorDirection = "B";
                    this.nStage=10;
                    this.nFairyIndex=16;
                    this.nFairyX = apl[this.nFairyIndex].x - this.oFairy.nFairyWidth;
                    logIt(this.nStage+": stage increment score:" + this.nScore);
        
                  }else{
                    this.endGameProcess((apl[11].y>apl[12].y)?apl[11].y:apl[12].y);
                  }
                
                logIt(this.sColorDirection +" hands closed going nStage:" + this.nStage);
  
                }
      
            }else{
  
              this.nEndCloseCount =0;
  
            }
      
          }

        }else{

          this.endGameProcess((apl[11].y>apl[12].y)?apl[11].y:apl[12].y);

        }


      } else if (this.nStage===0){
  
        if (apl[16].y < nMidSH && apl[15].y < nMidSH 
          && this.nHandClosedThreshold*nShoulderWidth > nFingerWidth 

          && apl[16].x < apl[15].x
          && checkStdev(aar_prevPts,15,1.5) && checkStdev(aar_prevPts,14,1.5)
          && checkStdev(aar_prevPts,25,1.5) && checkStdev(aar_prevPts,28,1.5)

          )
        {
            
            s_comment="Left to right. Round head";
            this.nRefShoulderWidth = nShoulderWidth;
            document.getElementById('TimeBar').style.display="block";
            this.nStage=2;
            //document.getElementById('scoreCount').innerText = "Stage: " +this.nStage;
            this.nGameStartTime = aTimeLapsed;
            //document.getElementById('canvasContainer').scrollTop = 0;
            logIt("Ex Pose found. ShoulderRefWidth: " +this.nRefShoulderWidth + " going nStage:" + this.nStage);
           
        }else{
          //setComment("Clasp a thin object. In front of chest.");
        }
  
      }else if (this.nStage===-3){

        this.nGameDuration = this.oSelector.assessSelection(apl)
        if (this.nGameDuration>0){
          
          this.nStage = 0;
        }

      }
  
      
    }

   
  }

  readComment(aTimeLapsed){

    if (this.sComment.length>0){

      instructNow(this.sComment);
      this.sPrevComment = this.sComment;
      this.nLastCommentTime = aTimeLapsed;

      this.sComment = "";
     

    }

  }

}

class selectionObj{
  constructor(aCanvasWidth, aArryOptionTexts, aArryOptionIcons,aArryOptionValues){

    this.nSelectionState = 0;
    this.bPrevChoice = false;
    this.nXCount=0;
    this.arryOptionText = aArryOptionTexts;
    this.arryOptionIcon = aArryOptionIcons;
    this.arryOptionValue = aArryOptionValues;
    this.nOptionIndex =0;
    this.nOptionIconHeight=140;
    this.nCanvasWidth = aCanvasWidth;

  }

  setIconHeight(aEHeight){

    if (aEHeight >0 && this.nOptionIconHeight-10 > aEHeight){
      if (aEHeight <60){
        logIt("force set icon height to 60")
        this.nOptionIconHeight = 60;
      }else{
        logIt("set icon height to: " +aEHeight)
        this.nOptionIconHeight =aEHeight;
      }
      
    }


  }

  manageOptionIndex(aChange){

    this.nOptionIndex += aChange;
  
    if (this.nOptionIndex < 0)
    this.nOptionIndex=0;
    else if (this.nOptionIndex>=this.arryOptionIcon.length){
      this.nOptionIndex = this.arryOptionIcon.length-1;
    }
    
  }

  showSelection(){

    if(this.nOptionIndex > 0){

      creImage(this.arryOptionIcon[this.nOptionIndex-1],(this.nCanvasWidth/2)+ 10 + (this.nOptionIconHeight/2)
      ,0, this.nOptionIconHeight/2,this.nOptionIconHeight/2);

    }

    creImage(this.arryOptionIcon[this.nOptionIndex],(this.nCanvasWidth/2)- (this.nOptionIconHeight/2)
    ,0, this.nOptionIconHeight,this.nOptionIconHeight);

    if (this.nOptionIndex+1 < this.arryOptionIcon.length){

      creImage(this.arryOptionIcon[this.nOptionIndex+1],(this.nCanvasWidth/2)- 10 -this.nOptionIconHeight
      ,0, this.nOptionIconHeight/2,this.nOptionIconHeight/2);

    }   

  }

  assessSelection(apl){

    if(apl[20].y < apl[2].y
      ){ // select option wrist over eyes

        if (this.bPrevChoice){ // choice made; PrevChoice to make sure the pose maintained for 2 cycles. Not glitch.

          logIt("b_prevChoice:" + this.bPrevChoice);
          this.bPrevChoice=false;
          return this.arryOptionValue[this.nOptionIndex];

        }else{
          logIt("First Pass set choice:" + this.bPrevChoice);
          this.bPrevChoice=true;
        }

      }else if(apl[16].y < apl[14].y){ // check for swipe movement coz wrist above elbow

        //logIt("checking for swipe movement");
        this.bPrevChoice = false;
        //swipe left seq
        if (this.nSelectionState===0 && apl[16].x <apl[12].x){ //right wrist right of right shoulder
          this.nSelectionState=1;
          //logIt("from 0 to : "+ this.nSelectionState);
        }else if(this.nSelectionState===1 && apl[16].x > apl[12].x && apl[16].x < apl[7].x ){// right wrist left of right shoulder before left ear
          this.nSelectionState=2;
          //logIt("from 1 to : "+ this.nSelectionState);
        }else if(this.nSelectionState===2 && apl[16].x > apl[7].x ){

          this.manageOptionIndex(1); // right wrist beyond left ear
          this.nSelectionState=0;
          //logIt("from 2 to : "+ this.nSelectionState);

        } else if (this.nSelectionState===0 && apl[16].x > apl[7].x){
          this.nSelectionState=3;
          //logIt("from 0 to : "+ this.nSelectionState);

        }else if (this.nSelectionState===3 && apl[16].x > apl[12].x && apl[16].x < apl[7].x ){

          this.nSelectionState=2;
          //logIt("from 3 to : "+ this.nSelectionState);

        }else if (this.nSelectionState===2 && apl[16].x <apl[12].x){

          this.manageOptionIndex(-1);
          this.nSelectionState=0;
          //logIt("from 2 to : "+ this.nSelectionState);
          
        }

      }else{

        this.bPrevChoice=false;
        logIt("reset choice as " + this.bPrevChoice);
        setComment(timeLapsed,this.arryOptionText[this.nOptionIndex] + "?");   

      }

      if(apl[16].y > apl[14].y){
        this.nSelectionState=0;

      } 

      return -3;

  }

  checkContinue(apl){

    if (apl[16].y < apl[14].y && apl[15].y < apl[13].y
      && apl[16].x > apl[15].x && apl[13].x > apl[14].x
      ){

        if (this.nXCount>6){ // hands crossed for 2 cycles
          return false;
        }else{
          this.nXCount++;
        }
        

    }else{
      this.nXCount=0;
    }

    return true;
  }

}

class DropObj{
  constructor(PosNum){
   
    this.spacing = (ln_videoWidth/9)/n_wSFactor;
    this.offSet = 10;
    this.width=25;
    logIt("in DropObj, videoWidth: " + ln_videoWidth + " spacing: " + this.spacing);
    if (this.spacing+this.offSet < this.width){
      this.width = (this.spacing+this.offSet)*0.8
    }
    this.height=this.width;
    
    this.fallY=(camera.canvas.height-this.height)/30;
    logIt("fallY:" + this.fallY + " height: " + camera.canvas.height);
    this.level=0;
    this.vX = PosNum*this.spacing;
    this.vY = 0;
    this.orientation = generateRandomIntegerInRange(0,4);
    this.PosX = PosNum;
    this.lastAnimate=timeLapsed;

  

  }

  dropIfValid(){

    if (timeLapsed - this.lastAnimate > 0.2){

      if (this.level>28){ //screen height/ 80 =6, 6-1= 5

        return false;
      }else{
        this.drop();
        return true;
      }

    }else{ // not time to drop yet

      this.draw();
      return true;

    }


  }

  draw(){

    //this.orientation = generateRandomIntegerInRange(0,4);
    var imgV = document.createElement('img');
    imgV.src = "pb"+this.orientation+".png";
    //this.orientation = generateRandomIntegerInRange(0,4);
    camera.drawPic(imgV,{x:this.vX+(this.orientation*5),y:this.vY},this.width,this.height);

  }

  getXPosition(){
    return this.PosX;
  }

  drop(){

    if (timeLapsed - this.lastAnimate >0.2){
      this.vY = this.vY + this.fallY;
      this.level++;
      //this.orientation++;
      this.orientation = generateRandomIntegerInRange(0,4);

      this.lastAnimate = timeLapsed;

    }

    this.draw();
    
  }


}

class TreeObj{

  constructor(an_position){

    this.position = an_position;

    this.imgC = document.createElement('img');
    this.imgC.src = "TF_fairyPeach.png";
    
    this.dimWidth = 400;
    this.dimHeight = 400;

    this.height = 0.6*camera.canvas.height;
    //this.width = 0.6*camera.canvas.width;
    this.width = this.height* this.dimWidth/this.dimHeight;
   
    this.y=(camera.canvas.height-this.height);
    this.animateTime =-1;

    //          creImage("xt.png",0,200,200,270);
  
    switch(an_position) {
      case 0:
      
        this.x = 0;
        
        break;
      case 1:

        this.x = (camera.canvas.width- this.width)/n_wSFactor;
        logIt("Original left x:" + this.x + " with wSfactor: " + n_wSFactor + " ");
        
        break;

        default:
        break;
      
    }
  }

  moveTree(){

    if (this.width>60){

      if (this.animateTime===-1){

        n_scoreCount++;
        //document.getElementById('scoreCount').innerText = n_scoreCount;
        this.animateTime=timeLapsed;
  
      }else if(timeLapsed - this.animateTime > 0.15){
  
        this.width = 0.9*this.width;
        this.height=0.9*this.height;
  
        this.animateTime=timeLapsed;
      }

      if (this.position ===1)
        this.x = (camera.canvas.width -this.width)/n_wSFactor;

      camera.drawPic(this.imgC,{x:this.x,y:this.y},this.width/n_vSFactor,this.height/n_vSFactor);

    }else{

      logIt("end animation");
      b_moveTree = false;

      this.animateTime=-1
      if (this.position ===1)
        if (n_scoreCount%4===0){
          instructNow("Neat. Skate to the left ");
        }else{
          instructNow("Good. Skate to the left ");
        }
        
      else{

        if (n_scoreCount%3===0){
          instructNow("Lovely. Now to the right");

        }else{

          instructNow("Great. Now to the right");
        }        

      }
        
    }
    //logIt("drawing covid x:" + this.cX +" y:" + this.cY);
  }

}

class TapObj{
  constructor(anDuration){
   
    this.nTargetDuration = anDuration;
   
    this.nStablePoseTarget=1;
    this.nHitDuration = 6;
    
    this.nExStartTime = timeLapsed;
    this.nStableTarget=1;
    this.arryTargetsList=["L","LC","LR","C","CR","R"];
   

    this.bExPoseReady=false; 
    this.nScore=0;

    this.nStartPose=0;   

    this.arrySpinOut=[];
    this.arryDisplayedTargets=[];

    this.oSelector = new selectionObj(camera.canvas.width,["play 1 minute","play 3 minutes","play 5 minutes","play continuous"]
    ,["F_1min.png","F_3min.png","F_5min.png","F_unlimited.png"],[60,180,300,99999]);

    this.nImgWidth =-1;
    this.nImgHeight =-1;

    this.n_bannerY=0;


  }

  setDisplayTargets(){
    
    let nTargetLocationIndex = generateRandomIntegerInRange(0,5);
    //let nTargetLocationIndex =1;

    logIt("Random Target location index: " + nTargetLocationIndex + " with code: " + this.arryTargetsList[nTargetLocationIndex]);

    this.arryDisplayedTargets=[];

    for (var i=0;i<this.arryTargetsList[nTargetLocationIndex].length;i++){

      logIt("pushing in location: " + this.arryTargetsList[nTargetLocationIndex].charAt(i));
      this.arryDisplayedTargets.push(this.arryTargetsList[nTargetLocationIndex].charAt(i));

    }

    this.nHitStartTime = timeLapsed;
    //this.bGetHit = true;

  }

  getXPos(asPos){

    if (asPos==="L"){

      return 0.7* camera.canvas.width;

    }else if (asPos==="C"){

      return 0.35* camera.canvas.width;

    }else {
    // must be R
     return 0;

    }

  }

  show(aTimeLapsed){

    logIt("this.nExStartTime: " + this.nExStartTime + " this.nTargetDuration: " + this.nTargetDuration)
    if (aTimeLapsed - this.nExStartTime < this.nTargetDuration){

      logIt("Still in play");
      document.getElementById('gameTime').innerText =  Math.round( aTimeLapsed - this.nExStartTime);
      
      if (this.nImgWidth>0 && this.nImgHeight>0 && this.arryDisplayedTargets.length>0){

        let nY = 0;
        let nX;
    
        logIt("in show, this.arryDisplayedTargets count: " + this.arryDisplayedTargets.length);
        for (let apos of this.arryDisplayedTargets){
    
          nX = this.getXPos(apos);
          
          logIt("display banana with nX: " + nX);
         
          creImage("banana.png",nX,nY,this.nImgWidth,this.nImgHeight)     
    
        }
    
  
      }
  
      if (this.arrySpinOut.length>0){
  
        let arryTempSpin=[]; 
    
        for (let spinObj of this.arrySpinOut){
    
          logIt("Let spin! spinObj.state: " + spinObj.state + " destin: " + spinObj.destin + " sping content: " + this.arrySpinOut.length);
    
          if (spinObj.state<6){
            spinObj.state++;
            let nRotateAngle = spinObj.state*45;
            let nPosX = this.getXPos(spinObj.destin),nPosY = spinObj.state* (camera.canvas.height-this.nImgHeight)/7
  
            if (nRotateAngle===225)
              nPosY = spinObj.state* (camera.canvas.height-this.nImgHeight)/7 + 0.5*this.nImgHeight;
    
            else if(nRotateAngle===270)
              nPosY = spinObj.state* (camera.canvas.height-this.nImgHeight)/7 + this.nImgHeight;
            else if(nRotateAngle===315)
              nPosY = spinObj.state* (camera.canvas.height-this.nImgHeight)/7+ 1.5*this.nImgHeight;
   
  
            if (nPosY > camera.canvas.height)
              nPosY = camera.canvas.height - this.nImgHeight;
    
            logIt("nPosX: " + nPosX +" nPosY: " + nPosY +" nRotateAngle:" + nRotateAngle 
            + " screen height: " + camera.canvas.height + " this.nImgHeight" + this.nImgHeight);
    
            creRotatedDropImage("banana.png",nPosX,nPosY,this.nImgWidth,this.nImgHeight,nRotateAngle);
            
            //arryTmpDisplay.push(new ObjDisplayFrame("banana.png",nPosX,nPosY,this.nWidth,this.nHeight,"",nRotateAngle)); 
            arryTempSpin.push(spinObj);
          }
          
    
        }
    
        this.arrySpinOut = arryTempSpin;
    
      }

    }else{
      logIt("Time ended");
      this.endGameProcess(this.nTargetDuration);
    }


  }



  assess(apl, aTimeLapsed,aar_prevPts){
   
    var bContinue = this.oSelector.checkContinue(apl);
    var bStageCompleted = false;

    logIt("in assess with continue: " + bContinue);
    if (bContinue){

      if (this.nImgHeight===-1 && checkPartsStability(aar_prevPts,[objPartIndexMap.le,objPartIndexMap.rm],3)){


        logIt("facial stable. Setting Img dim");
        this.nImgHeight =getArrayAvg(aar_prevPts,objPartIndexMap.le,"y") - (getArrayAvg(aar_prevPts,objPartIndexMap.lm,"y") - getArrayAvg(aar_prevPts,objPartIndexMap.le,"y"));

        if (this.nImgHeight < 0.25*camera.canvas.height)
          this.nImgHeight = 0.25*camera.canvas.height

        this.nImgWidth = 0.3*camera.canvas.width;

        logIt(" Avg le position Y: " + getArrayAvg(aar_prevPts,objPartIndexMap.le,"y") 
        + " Avg lm position Y: " + getArrayAvg(aar_prevPts,objPartIndexMap.lm,"y") 
        + " nImgHeight: " + this.nImgHeight + " nImgWidth: " + this.nImgWidth );


      }

      // 1 cycle only do either
      if (this.arryDisplayedTargets.length===0 || (timeLapsed - this.nHitStartTime > this.nHitDuration && this.nHitStartTime>0)){
        // if all cleared the set new targets

        logIt("Setting target in assess");
        this.setDisplayTargets();

      }else{

        let arryTmpTargets=[];
        logIt("got targets to display");
        for (let targetPos of this.arryDisplayedTargets){
          let nThisTargetX = this.getXPos(targetPos);
          
          logIt("nThisTargetX: " + nThisTargetX + " from targetPos: " + targetPos);
          if ((apl[objPartIndexMap.lw].y< this.nImgHeight 
            && apl[objPartIndexMap.lw].x > nThisTargetX 
            && apl[objPartIndexMap.lw].x < (nThisTargetX+this.nImgWidth))
            ||(apl[objPartIndexMap.rw].y< this.nImgHeight 
              && apl[objPartIndexMap.rw].x > nThisTargetX
              && apl[objPartIndexMap.rw].x < (nThisTargetX+this.nImgWidth))
            ){

            logIt("playing magic bell");
            PlaySound(ad_shuttle,"magicBell.mp3",false);
            this.arrySpinOut.push({state:0,destin:targetPos});
            //this.arrySpinOut.push({});

          }else{

            arryTmpTargets.push(targetPos);

          }

        }

        this.arryDisplayedTargets = arryTmpTargets;

      }

    }else{
      logIt("End with access");
      this.n_bannerY= (apl[11].y>apl[12].y)?apl[11].y:apl[12].y;
      this.endGameProcess(Math.round( aTimeLapsed - this.nExStartTime));
    }


  }

  endGameProcess(aDuration){

    //sendData("yys");
    b_inEx=false;
    this.nStage=-4;
    showEndBanner(this.n_bannerY);
    document.getElementById('gameTime').innerText = aDuration;
    document.getElementById('share').style.display = "none";
    PlaySound(ad_countDown,"BellCelebrateHorn.mp3",false);
    logIt("in endGameProcess, setting good job");
    setComment(timeLapsed,"Well Done!Good Job!");

  }

}

class PuzzleObj{
  constructor(anDuration){
   
    this.nTargetDuration = anDuration;
   
    this.nStablePoseTarget=1;
    this.nHitDuration = 6;
    
    this.nExStartTime = timeLapsed;
    this.nStableTarget=1;

    this.bExPoseReady=false; 
    this.nScore=0;

    this.nStartPose=0;   

    this.arrySpinOut=[];
    this.arryDisplayedTargets=[];


    this.nStableY=0;

    this.nImgWidth =-1;
    this.nImgHeight =-1;

    this.n_bannerY=0;

    //this.arrySPLocations=["LT","LM","RT","RM"];
    this.arrySPLocations=["LT","RT"];

    this.bInHand=false;
    this.arrySnapped = [];
    this.sMP="";
    this.arryPreFix = ["a","b","c"];
    this.arryMP = ["t1","t2","t3","t4","m1","m2","m3","m4","b1","b2","b3","b4"];
    this.sMPSpot="";
    this.nHoldingIndex=0;
    this.nHoldingX = -1;
    this.nHoldingY=-1;
    this.bShowPiece=true;

    this.nPhase=-2;
    this.nStartTime = timeLapsed;
    this.nPuzzleDone=0;
    this.nAllDurations = [];


  }

  setDisplayTarget(){
    
    if (this.arryMP.length>0){

      let nMPIndex = generateRandomIntegerInRange(0,this.arryMP.length-1);

      //nMPIndex=this.arryMP.length-1;

      this.sMP = this.arryMP[nMPIndex];
  
      //this.sMP="M";
      this.arryMP.splice(nMPIndex,1);
  
  
      this.sMPSpot=this.arrySPLocations[generateRandomIntegerInRange(0,this.arrySPLocations.length-1)];
      //this.sMPSpot="RM";

    }else{

      this.sMPSpot="";

    }
    

   /* 
    for (var i=0;i<this.arryTargetsList[nTargetLocationIndex].length;i++){

      logIt("pushing in location: " + this.arryTargetsList[nTargetLocationIndex].charAt(i));
      this.arryDisplayedTargets.push(this.arryTargetsList[nTargetLocationIndex].charAt(i));

    }

    this.nHitStartTime = timeLapsed;

    */

    //this.bGetHit = true;

  }

  showStaticPiece(sPieceID, sLocation){

    let mpX,mpY,mpWidth,mpHeight=0;

    let imgName="";

    mpWidth = 0.3*camera.canvas.width;
    mpHeight = 0.2*camera.canvas.height;

    if (sLocation==="C"){ //snapped pieces

      if (sPieceID==="Y"){

        imgName="YPose.png";

        mpWidth = 0.4*camera.canvas.width;
        mpHeight = 0.60*camera.canvas.height;
  
        mpX = 0.3*camera.canvas.width; 
        mpY = 0.4*camera.canvas.height;

      }else if (sPieceID==="Try"){

        imgName="niceTray.png";

        mpWidth = 0.9*camera.canvas.width;
        mpHeight = 0.60*camera.canvas.height;
  
        mpX = 0.05*camera.canvas.width; 
        mpY = 0.2*camera.canvas.height;

      }
      
      else if (sPieceID==="C"){

        
        imgName=this.arryPreFix[this.nPuzzleDone-1]+".png";
        

        mpWidth = 0.9*camera.canvas.width;
        mpHeight = 0.60*camera.canvas.height;
  
        mpX = 0.05*camera.canvas.width; 
        mpY = 0.2*camera.canvas.height;

      }
      
      else if (sPieceID==="F"){

       
        imgName=this.arryPreFix[this.nPuzzleDone]+".png"


        mpWidth = 0.9*camera.canvas.width;
        mpHeight = 0.60*camera.canvas.height;
  
        mpX = 0.05*camera.canvas.width; 
        mpY = 0.25*camera.canvas.height;

      }else if (sPieceID==="BP"){

        imgName="f_numMatrix.png";

        mpWidth = 0.9*camera.canvas.width;
        mpHeight = 0.60*camera.canvas.height;
  
        mpX = 0.05*camera.canvas.width; 
        mpY = 0.25*camera.canvas.height;

      }
      else {
// handle all the snapped
        let oGridSpot = this.getGridBox(sPieceID);

        imgName=this.arryPreFix[this.nPuzzleDone]+sPieceID+ ".png";

        mpX = oGridSpot.minX
        mpY = oGridSpot.minY;

        mpWidth = 0.225*camera.canvas.width;
        mpHeight = 0.20*camera.canvas.height;

      }

    }else if (this.sMPSpot.length>0){

      mpWidth = 0.4*camera.canvas.width;
      mpHeight = 0.3*camera.canvas.height;

      if (this.sMPSpot.startsWith("L")){
        mpX = 0.6*camera.canvas.width;
  
      }else{
        mpX = 0;
      }
  
      if (this.sMPSpot.endsWith("T")){
        mpY=0;
        
      }else{
        mpY=0.05* camera.canvas.height;
      }

      imgName = this.arryPreFix[this.nPuzzleDone] + sPieceID + ".png";

    }

    //logIt("creImage: " + imgName + " x:" + mpX + " y:" + mpY+" width:" + mpWidth + " height:" + mpHeight);
    creImage(imgName,mpX,mpY,mpWidth,mpHeight);


  }

  getXPos(asPos){

    if (asPos==="L"){

      return 0.7* camera.canvas.width;

    }else if (asPos==="C"){

      return 0.35* camera.canvas.width;

    }else {
    // must be R
     return 0;

    }

  }

  displayInHand(){

    let sPieceName =this.arryPreFix[this.nPuzzleDone]+ this.sMP+".png";

    let nImgWidth = 0.4*camera.canvas.width;
    let nImgHeight = 0.3* camera.canvas.height;

   // let nImgWidth = 0.04*camera.canvas.width;
   // let nImgHeight = 0.03* camera.canvas.height;

    let imgX = this.nHoldingX - nImgWidth/2;
    let imgY = this.nHoldingY - nImgHeight/2;

    creImage(sPieceName,imgX,imgY,nImgWidth,nImgHeight);


  }


  show(aTimeLapsed){

    logIt("this.nExStartTime: " + this.nExStartTime + " this.nTargetDuration: " + this.nTargetDuration)
   

      logIt("Still in play");

      if (this.nPhase===-1){

        this.showStaticPiece("Y","C");

      }else if (this.nPhase===0 && (aTimeLapsed - this.nStartTime) < 3){

        this.showStaticPiece("F","C");

      }else if (this.nPhase===0 && (aTimeLapsed - this.nStartTime) > 3){

        if (this.bShowPiece){
          this.setDisplayTarget();
          this.bShowPiece=false;
        }
        this.nPhase=1;

      }else if(this.nPhase===1){


        document.getElementById('gameTime').innerText =  (Math.round( aTimeLapsed - this.nStartTime) -3) + stats.sum(this.nAllDurations);

        if ((Math.round( aTimeLapsed - this.nStartTime) -3) < this.nTargetDuration){

            //b_displayTime = true;
            this.showStaticPiece("BP","C");

            if (this.arrySnapped.length>0){

              logIt("display snapped");
              for (var i=0;i<this.arrySnapped.length;i++) {
                //this.showStaticPiece(this.arrySnapped[i],"C");
                //logIt("smapping: " + this.arrySnapped[i]);

                logIt("snap pic: " + this.arrySnapped[i].imgName + " x:" +this.arrySnapped[i].x
                + " y:" +this.arrySnapped[i].y +" w:" +this.arrySnapped[i].w + " h:" + this.arrySnapped[i].h );

                creImage(this.arrySnapped[i].imgName,this.arrySnapped[i].x,this.arrySnapped[i].y,this.arrySnapped[i].w,this.arrySnapped[i].h);

              }
            }




          if (this.sMP!==""){

            if (this.bInHand){

              this.displayInHand();


            }else
              this.showStaticPiece(this.sMP,this.sMPSpot);

          }

        }else{

          this.endGameProcess();

        }


      //creImage("MS.png",nX,nY,this.nImgWidth,this.nImgHeight)


      }else if (this.nPhase===3 && (aTimeLapsed - this.nStartTime) < 5){

        if (this.nScore<12)
          this.showStaticPiece("Try","C");
        else
          this.showStaticPiece("C","C");


      }else if (this.nPhase===3 && (aTimeLapsed - this.nStartTime) > 5){

        this.bExPoseReady=false;
        this.nPhase=-2;
        this.nScore=0;
        this.sMP="";

        this.bInHand=false;
        this.arrySnapped = [];
        this.sMP="";
        this.arryMP = ["t1","t2","t3","t4","m1","m2","m3","m4","b1","b2","b3","b4"];
        this.nStableY=0;
        this.sMPSpot="";
        this.nHoldingIndex=0;
        this.nHoldingX = -1;
        this.nHoldingY=-1;
        this.bShowPiece=true;


        if (b_actualPlay){

          if (this.nPuzzleDone===3){


            this.completedSegment();
  
          }

        }else
          this.completedSegment();


      }

   

  }


  checkGrabPiece(apl){

    if (this.sMPSpot==="LT"){

      if(apl[objPartIndexMap.li].x > 0.6*camera.canvas.width && apl[objPartIndexMap.li].y < 0.3*camera.canvas.height ){

        this.bInHand = true;
        this.nHoldingIndex = objPartIndexMap.li;

      }

    }else if(this.sMPSpot==="LM"){

      if(apl[objPartIndexMap.li].x > 0.7*camera.canvas.width 
        && apl[objPartIndexMap.li].y < 0.35*camera.canvas.height && apl[objPartIndexMap.li].y > 0.2*camera.canvas.height){

        this.bInHand = true;
        this.nHoldingIndex = objPartIndexMap.li;

      }

    }else if(this.sMPSpot==="RT"){

      if(apl[objPartIndexMap.ri].x < 0.4*camera.canvas.width 
        && apl[objPartIndexMap.ri].y < 0.3*camera.canvas.height){

        this.bInHand = true;
        this.nHoldingIndex = objPartIndexMap.ri;

      }

    }else if(this.sMPSpot==="RM"){

      if(apl[objPartIndexMap.ri].x < 0.3*camera.canvas.width 
        && apl[objPartIndexMap.ri].y < 0.35*camera.canvas.height && apl[objPartIndexMap.ri].y > 0.2*camera.canvas.height){

        this.bInHand = true;
        this.nHoldingIndex = objPartIndexMap.ri;

      }

    }


  }

  getGridBox(asMP){

    let nMinY, nMaxY,nMinX,nMaxX;

    if (asMP.startsWith("t")){


      nMinY = 0.25*camera.canvas.height;
      nMaxY = 0.45*camera.canvas.height;

      /*
      if (apl[this.nHoldingIndex].x > 0.35*camera.canvas.width && apl[this.nHoldingIndex].x < 0.65*camera.canvas.width
        && apl[this.nHoldingIndex].y> 0.40*camera.canvas.height && apl[this.nHoldingIndex].y< 0.55*camera.canvas.height ){

          this.bInHand=false;
          this.arrySnapped.push(this.sMP);
          this.sMP="";
          this.setDisplayTarget();
          this.nScore++;

        }*/

    }else if (asMP.startsWith("m")){

      nMinY = 0.45*camera.canvas.height;
      nMaxY = 0.65*camera.canvas.height;

    }else if (this.sMP.startsWith("b")){

      nMinY = 0.65*camera.canvas.height;
      nMaxY = 0.85*camera.canvas.height;

    }

    if (asMP.endsWith("1")){

      nMinX = 0.725*camera.canvas.width;
      nMaxX = 0.95*camera.canvas.width;

    }else if (asMP.endsWith("2")){

      nMinX = 0.5*camera.canvas.width;
      nMaxX = 0.725*camera.canvas.width;

    }else if (asMP.endsWith("3")){

      nMinX = 0.275*camera.canvas.width;
      nMaxX = 0.5*camera.canvas.width;

    }else if (asMP.endsWith("4")){

      nMinX = 0.05*camera.canvas.width;
      nMaxX = 0.275*camera.canvas.width;

    }

    return {minX: nMinX, maxX: nMaxX,minY: nMinY, maxY: nMaxY};


  }
  checkSnapPiece(apl){

    let oGridBox = this.getGridBox(this.sMP);


    if (apl[this.nHoldingIndex].x > oGridBox.minX && apl[this.nHoldingIndex].x < oGridBox.maxX
      && apl[this.nHoldingIndex].y> oGridBox.minY && apl[this.nHoldingIndex].y< oGridBox.maxY ){

        this.bInHand=false;


        this.arrySnapped.push({imgName:this.arryPreFix[this.nPuzzleDone]+this.sMP+".png", x: oGridBox.minX,y:oGridBox.minY,w: 0.225*camera.canvas.width, h:0.20*camera.canvas.height });
        this.sMP="";
        this.setDisplayTarget();
        this.nScore++;

      }



  }

  checkInitialCondition(apl){

    //document.getElementById("startcondition").innerText = ((apl[objPartIndexMap.lfi].y - apl[objPartIndexMap.re].y)/camera.canvas.height).toFixed(2);

    if (apl[objPartIndexMap.lfi].x < camera.canvas.width && apl[objPartIndexMap.lfi].x >0
      && apl[objPartIndexMap.rfi].x < camera.canvas.width && apl[objPartIndexMap.rfi].x >0
      && apl[objPartIndexMap.li].x < camera.canvas.width && apl[objPartIndexMap.li].x >0
      && apl[objPartIndexMap.ri].x < camera.canvas.width && apl[objPartIndexMap.ri].x >0
      && apl[objPartIndexMap.lfi].y < camera.canvas.height && apl[objPartIndexMap.lfi].x >0
      && apl[objPartIndexMap.rfi].y < camera.canvas.height && apl[objPartIndexMap.rfi].x >0
      && apl[objPartIndexMap.le].y < camera.canvas.height && apl[objPartIndexMap.le].x >0
      && apl[objPartIndexMap.re].y < camera.canvas.height && apl[objPartIndexMap.re].x >0
      && (apl[objPartIndexMap.lfi].y - apl[objPartIndexMap.re].y) > 0.7*camera.canvas.height){

        this.bExPoseReady=true;
        this.nPhase=-1;
       // document.getElementById("startcondition").innerText =this.nPhase;
        //this.setDisplayTarget();

    }


  }

  checkYPose(apl){

    if (apl[objPartIndexMap.rw].x < apl[objPartIndexMap.reb].x && apl[objPartIndexMap.reb].x < apl[objPartIndexMap.rs].x
      && apl[objPartIndexMap.ls].x < apl[objPartIndexMap.leb].x && apl[objPartIndexMap.leb].x < apl[objPartIndexMap.lw].x
      && apl[objPartIndexMap.rw].y < apl[objPartIndexMap.reb].y && apl[objPartIndexMap.reb].y < apl[objPartIndexMap.rs].y
      && apl[objPartIndexMap.lw].y < apl[objPartIndexMap.leb].y && apl[objPartIndexMap.leb].y < apl[objPartIndexMap.ls].y
      ){
        return true;
      }else{
        return false;
      }

  }



  assess(apl, aTimeLapsed,aar_prevPts){
   
    //var bContinue = this.oSelector.checkContinue(apl);
    var bContinue=true;
    var bStageCompleted = false;

    if (!this.bExPoseReady){
      this.checkInitialCondition(apl);
    }else if (this.nPhase===-1){

      if (this.checkYPose(apl)){

        this.nStableY++;

        if (this.nStableY>3){

          this.nPhase = 0;
          this.nStartTime = timeLapsed;
          //document.getElementById("startcondition").innerText = this.nPhase;

        }
        

      }else
        this.nStableY=0;
       

      //document.getElementById("startcondition").innerText = this.nStableY;

    }else{

      logIt("in assess with continue: " + bContinue);

      if (this.nPhase===1){

        if (this.nScore<12 && this.sMPSpot!==""){
          this.checkGrabPiece(apl);
        }
    
        if (this.bInHand){
    
          this.nHoldingX = apl[this.nHoldingIndex].x;
          this.nHoldingY = apl[this.nHoldingIndex].y;
    
          this.checkSnapPiece(apl);

          if (this.nScore===12)
            this.endGameProcess();
    
        }

      }



    }


  }

  endGameProcess(){

    //sendData("yys");
    //b_inEx=false;
    //this.nPhase=-2;
    this.nPuzzleDone++;

    let nThisDuration = (Math.round( timeLapsed - this.nStartTime) -3);

    if (nThisDuration > this.nTargetDuration)
      nThisDuration = this.nTargetDuration;
    this.nAllDurations.push(nThisDuration);

    this.nStartTime = timeLapsed;
    this.nPhase = 3;

    if (this.nPuzzleDone===3 && b_actualPlay)
      sendTime(s_teamName,stats.sum(this.nAllDurations));

    //this.nStage=-4;
    //showEndBanner(this.n_bannerY);


  }

  completedSegment(){

    b_predict=false;
    //b_runTime = false;
    b_displayTime=false;

    document.getElementById('nc').style.display="block";
    
    getContent();
    
    document.documentElement.scrollTop =0;
    document.getElementById('gameTime').innerText=0;
    document.getElementById('ExView').style.display="none";

  }

}

class DanceObj{
  constructor(){

    this.nStopTolerance = 2;
    this.nFadeTiming = 1; 
    this.nCutTime=10;
    this.nInitBuffer=15;
    this.nGaming=timeLapsed;
    this.nGameStart = timeLapsed;

    this.nAnimationPhase=1;
    this.nAnimationBase=5;
    this.nDiminish=1;

    this.nCorner=0;
    this.nColor=0;

    this.nDifference=1;
    this.nSafeLevel=-1;

    //this.bEmergency=false;

    this.nEmergency=0;
  
    //this.startSong();

  }


  checkPlayingCondition(apl,aar_prevPts){

    //document.getElementById("startcondition").innerText = ((apl[objPartIndexMap.lfi].y - apl[objPartIndexMap.re].y)/camera.canvas.height).toFixed(2);

    if (
      apl[objPartIndexMap.li].x < camera.canvas.width && apl[objPartIndexMap.li].x >0
      && apl[objPartIndexMap.ri].x < camera.canvas.width && apl[objPartIndexMap.ri].x >0

      && apl[objPartIndexMap.le].y < camera.canvas.height && apl[objPartIndexMap.le].x >0
      && apl[objPartIndexMap.re].y < camera.canvas.height && apl[objPartIndexMap.re].x >0

      && apl[objPartIndexMap.leb].y > apl[objPartIndexMap.lw].y && apl[objPartIndexMap.reb].y > apl[objPartIndexMap.rw].y
      && getArrayStdev(aar_prevPts,objPartIndexMap.lw,"x") >2 && getArrayStdev(aar_prevPts,objPartIndexMap.rw,"x") >2
      
      ){
       
        if ( this.nSafeLevel < 0)
          this.nSafeLevel = apl[objPartIndexMap.lh].y

        this.nGaming = timeLapsed;

    }


  }

  assess(apl, aTimeLapsed,aar_prevPts){

    //document.getElementById("tName").innerText = this.nGaming;
    document.getElementById("gameTime").innerText = Math.round(timeLapsed);

    this.checkPlayingCondition(apl, aar_prevPts);

    if (apl[0].y> this.nSafeLevel){
      pauseSong();
      this.nEmergency++;

      if (this.nEmergency>5){

        setComment(timeLapsed, "Are you ok?");

      }else{
        
        document.getElementById("emergencyPic").style.display="block";

        if (arry_safety.length>4){

          document.getElementById("f0").src = arry_safety[0];
          document.getElementById("f1").src = arry_safety[1];
          document.getElementById("f2").src = arry_safety[2];
          document.getElementById("f3").src = arry_safety[3];
          document.getElementById("f4").src = arry_safety[4];

        }

        this.bEmergency=true;
      }

     



    }else{

      this.nEmergency=0

      if (timeLapsed -this.nGameStart> this.nInitBuffer){


        if(this.nGaming < this.nInitBuffer)
          this.nGaming = this.nInitBuffer
  
        if (timeLapsed - this.nGaming > this.nStopTolerance){
  
  
          let nDifference = Math.round(timeLapsed - this.nGaming);
  
          if (nDifference < this.nCutTime){
    
            gainNode.gain.value = -1*0.1*nDifference;
  
            if (gainNode.gain.value < 0){
  
              if (gainNode.gain.value > -0.25)
                this.nDiminish = 0.7;
              else if (gainNode.gain.value > -0.5)
                this.nDiminish = 0.5;
              else if (gainNode.gain.value > -0.7)
                this.nDiminish = 0.25;
            }
    
    
          }else{
  
            pauseSong();
            this.nDiminish =0;
  
          }
  
        }else{
  
          if (gainNode.gain.value <0){
            gainNode.gain.value = Math.abs(gainNode.gain.value);
            this.nDifference=1;
          }
          
  
          startSong();
          this.nDiminish =1;
  
        }
  
      }

    }


      

  }

  show(aTimeLapsed){

    if (this.nEmergency>5){


      creImage("emergency.png",0,0,camera.canvas.width,camera.canvas.height);

    }else{

      if (this.nAnimationPhase===2){

        arry_safety.push(camera.canvas.toDataURL());
  
        if (arry_safety.length===n_poseLookBack)
          arry_safety.shift();
  
      }
  
      let nImgWidth = this.nDiminish*(this.nAnimationPhase/this.nAnimationBase) * camera.canvas.width;
      let nImgHeight = this.nDiminish*(this.nAnimationPhase/this.nAnimationBase) * camera.canvas.height;
  
      let yPos = 0;
      let xPos = 0;
  
      if (this.nCorner===0){
  
        yPos = camera.canvas.height - nImgHeight;
        xPos = camera.canvas.width  - nImgWidth;
  
      }else if (this.nCorner===1){
  
        yPos = 0;
        xPos = 0;
  
  
      }else if (this.nCorner===2){
  
        yPos = camera.canvas.height - nImgHeight;
        xPos = 0;
        
      }else if (this.nCorner===3){
  
        yPos = 0;
        xPos = camera.canvas.width  - nImgWidth;
        
      }

  
      creImage(this.nColor+".jfif",xPos,yPos,nImgWidth,nImgHeight);
  
      this.nAnimationPhase++;
  
      if (this.nAnimationPhase>this.nAnimationBase){
  
        this.nAnimationPhase=1;
        this.nCorner++;
        if (this.nCorner>3)
          this.nCorner=0;
  
          this.nColor++;
        if (this.nColor>5)
          this.nColor=0;
  
      }
  
    }

    }

}

function startSong(){


  if (audioCtx.state !== "running"){
    audioCtx.resume().then(function(){

      gainNode.gain.value = 1;
      song.play();

     });

  }

}

function pauseSong(){

  if (audioCtx.state === "running"){

    audioCtx.suspend().then(function(){
      song.pause();
    });

  }
}



function checkPartsStability(aarryPoseSeq,aarryIndiceToCheck,anStdev){

  for (let nIndex of aarryIndiceToCheck){

    if (getArrayStdev(aarryPoseSeq,nIndex,"y")>anStdev || getArrayStdev(aarryPoseSeq,nIndex,"x")>anStdev){

      logIt("index: " + nIndex + " not stable. y: "+getArrayStdev(aarryPoseSeq,nIndex,"y") + " x: " + getArrayStdev(aarryPoseSeq,nIndex,"x"));
      return false;

    }

  }
  logIt("Parts are stable");
  return true;

}

function getArrayAvg(aarry, an_poseIndex, as_property){

  var tmp_arry=[];

  for (var t=0; t< aarry.length; t++){
   
    if (as_property==="x")
      tmp_arry.push(aarry[t][an_poseIndex].x);
    else
      tmp_arry.push(aarry[t][an_poseIndex].y);

  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.mean(tmp_arry);

}

function getArrayStdev(aarry, an_poseIndex, as_property){

  var tmp_arry=[];

  for (var t=0; t< aarry.length; t++){
   
    if (as_property==="x")
      tmp_arry.push(aarry[t][an_poseIndex].x);
    else
      tmp_arry.push(aarry[t][an_poseIndex].y);

  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.stdev(tmp_arry);

}

function UpdateReward(){

  if (s_name.length>0 ){

    var dYesterday = new Date(new Date().setDate(new Date().getDate() - 1));
   
    var dToday = new Date();

    var dNextYear =new Date(new Date().setFullYear(new Date().getFullYear() + 1));

 

      if (d_lastPlay===-1 ||(d_lastPlay.getDate() ===dYesterday.getDate()&& d_lastPlay.getMonth() ===dYesterday.getMonth()) ){ // 

        n_myCoupon++;
  // 3,5,7
  
        var lTier=0;
        if (n_myCoupon > 6){
          lTier = 3;
        }else if (n_myCoupon > 4){
          lTier = 2;
        }else if (n_myCoupon > 2){
          lTier = 1;
  
        }
  
        if (lTier > n_tierAchieved)
          n_tierAchieved = lTier;
  
      }else{
        if (!(d_lastPlay.getDate() ===dToday.getDate()&& d_lastPlay.getMonth() ===dToday.getMonth())){
          n_myCoupon=1;
        }
        
      }

      /*
      document.cookie = "BLSRName="+s_name + "; expires=" + dNextYear.toUTCString();
      document.cookie = "DateLastPlayed="+dToday+"; expires=" + dNextYear.toUTCString();
      document.cookie = "TierAchievered="+n_tierAchieved+"; expires=" + dNextYear.toUTCString();
      document.cookie = "CurrentSeq="+n_myCoupon+"; expires=" + dNextYear.toUTCString()

      document.cookie = "BLSRName="+s_name + ";" ;
      document.cookie = "DateLastPlayed="+dToday+";";
      document.cookie = "TierAchievered="+n_tierAchieved+";";
      document.cookie = "CurrentSeq="+n_myCoupon+";";
      */

      d_lastPlay = dToday;

      document.cookie = "BLSRName="+s_name + "; expires=" + dNextYear.toUTCString();
      document.cookie = "DateLastPlayed="+dToday+"; expires=" + dNextYear.toUTCString();
      document.cookie = "TierAchievered="+n_tierAchieved+"; expires=" + dNextYear.toUTCString();
      document.cookie = "CurrentSeq="+n_myCoupon+"; expires=" + dNextYear.toUTCString();

      logIt("BLSRName="+s_name);
      logIt("DateLastPlayed="+dToday);
      logIt("TierAchievered="+n_tierAchieved);
      logIt("CurrentSeq="+n_myCoupon);

      //document.getElementById('blsrProgress').style.display="block";

      
      //document.getElementById('residentName').innerText = s_name;

      //document.getElementById('TierAchievered').innerText = n_tierAchieved;
      //document.getElementById('DateLastPlayed').innerText = formatDate(d_lastPlay);

      //document.getElementById('CurrentSeq').innerText = n_myCoupon;

      //logIt("blsrProgress blocked and values set: " + formatDate(d_lastPlay) + " conSeq:" + n_myCoupon);

      //sendData("Cookies");


    // dYesterday.getDate()  getMonth() blsrProgress



  }



}

function setComment(aTimeLapsed, aCommentStr){

  if (typeof(aCommentStr) === "undefined"){
    logIt("detected undefined str");
  }else
  if(aTimeLapsed - n_lastCommentTime > ln_configRA || s_prevComment!==aCommentStr){

    logIt("Set comment: " + aCommentStr);
    s_comment = aCommentStr;

  }

}

function sayComment(aTimeLapsed){

  if (s_comment.length>0){

    instructNow(s_comment);
    s_prevComment = s_comment;
    n_lastCommentTime = aTimeLapsed;

    s_comment = "";
   

  }

}

function getIndex(aarryExistingIndex){

  var ln_randPosition = generateRandomIntegerInRange(0,7);
  var b_searchingIndex = true;

  while(b_searchingIndex){

    if (aarryExistingIndex.includes(ln_randPosition)){
      ln_randPosition++;

      if (ln_randPosition > 7){
        ln_randPosition=0;
      }
    }else{
      b_searchingIndex = false;
    }
  }
  return ln_randPosition;
}


function getAvgX(aarry, an_index){

  var tmp_arryX=[];

  for (var t=0; t< aarry.length; t++){
   
    tmp_arryX.push(aarry[t][an_index].x);
  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.mean(tmp_arryX);

}

function getAvgY(aarry, an_index){

  var tmp_arryY=[];

  for (var t=0; t< aarry.length; t++){
   
    tmp_arryY.push(aarry[t][an_index].y);
  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryY:" +stats.mean(tmp_arryY) );

  return stats.mean(tmp_arryY);

}

function checkStdev(aarry, an_index, an_sstev){

  var tmp_arryX=[];
  var tmp_arryY=[];
  for (var t=0; t< aarry.length; t++){
    tmp_arryX.push(aarry[t][an_index].x);
    tmp_arryY.push(aarry[t][an_index].y);
  }

  /*
  logIt("length arry: " + aarry.length + " stdev tmp_arryX:" +stats.stdev(tmp_arryX)
  + " stdev tmp_arryY:" +stats.stdev(tmp_arryY) );
  */
  //document.getElementById('gameTime').innerText = "stdevX: " + stats.stdev(tmp_arryX) + " Y:" + stats.stdev(tmp_arryY)
  if (stats.stdev(tmp_arryX)< an_sstev && stats.stdev(tmp_arryY)< an_sstev){
    return true;
  }else{
    logIt("not stable:" + an_index);
    return false;
  }


}

function setUpDeviceParam(){

  var ln_vWidth=document.getElementById('video').videoWidth; 

  var ln_vHeight=document.getElementById('video').videoHeight; 
  var n_scaleFactor = 0;

  var b_isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

  logIt("is it mobile:" +b_isMobile);

  logIt("video size: width:" + ln_vWidth + " height:" + ln_vHeight);
  logIt("screen size: width:" + window.screen.width + " height:" + window.screen.height);
      
  if (window.screen.height > window.screen.width ){ // portrait


    if (ln_vWidth > window.screen.width){

      document.getElementById('canvasContainer').style.width = window.screen.width+"px";
      document.getElementById('canvasContainer').style.height = window.screen.height+"px";
  
      if(window.screen.width < ln_vWidth){
        n_scaleFactor = window.screen.width/ln_vWidth;
      }

    }





  }else {

    if (window.screen.height>=720 && window.screen.width>=960 ){


     // document.getElementById('canvasContainer').style.width ="960px";
    //  document.getElementById('canvasContainer').style.height = "720px";

      n_scaleFactor = 960/ln_vWidth; 
    }else if (window.screen.height >= ln_vHeight && window.screen.width >= ln_vWidth){
      n_scaleFactor=1;
     // document.getElementById('canvasContainer').style.width =ln_vWidth+"px";
     // document.getElementById('canvasContainer').style.height = ln_vHeight+"px";

    }else{

    //  document.getElementById('canvasContainer').style.width = window.screen.width+"px";
    //  document.getElementById('canvasContainer').style.height = window.screen.height+"px";

      if(window.screen.width < ln_vWidth){
        n_scaleFactor = window.screen.width/ln_vWidth;
      }
    }


    document.getElementById('canvasContainer').scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
    

  }


  logIt("scale factor:" +n_scaleFactor);
  return {n_videoWidth:ln_vWidth,n_videoHeight:ln_vHeight,n_scaleFactor: n_scaleFactor, b_isMobile: b_isMobile }
      //document.getElementById('sLevel').innerHTML = "ln_vWidth:" + ln_vWidth + ", ln_vHeight:" + ln_vHeight;
  
}

function showEndBanner(aY){
  
  var w = 525, h=250;
  //instruct("Feet wider than shoulder. Bend knees and lean forward. Bend your arms");
  logIt("y position:" + aY);
  creImage("jb.PNG",ln_videoWidth/2 - w/2,aY, w,h);

  document.getElementById('refresh').style.display="block";
 
}

function creRotatedDropImage(imgName,x,y,w,h,angDegrees){

  var imgC = document.createElement('img');
  imgC.src = imgName;
  //logIt("Creating img:" + imgName + " x:" + x + " y:" +y); drawRotatePic

  if ((y+h)>camera.canvas.width )
    y = camera.canvas.width-h;

  try{
    logIt("Creating drop img");
    camera.drawRotateDropPic(imgC,{x:x,y:y},w,h,angDegrees);
  }catch(err){
    logIt(imgName +"- Error drawing:" + err.message);
  }
  

}

function creImage(imgName,x,y,w,h){

  var imgC = document.createElement('img');
  imgC.src = imgName;
  //logIt("Creating img:" + imgName + " x:" + x + " y:" +y); drawRotatePic
  try{
    camera.drawPic(imgC,{x:x,y:y},w,h);
  }catch(err){
    logIt(imgName +"- Error drawing:" + err.message);
  }
  

}

function creRotatedImage(imgName,x,y,w,h,angDegress){

  var imgC = document.createElement('img');
  imgC.src = imgName;
  //logIt("Creating img:" + imgName + " x:" + x + " y:" +y); drawRotatePic

  if ((y+h)>camera.canvas.width )
    y = camera.canvas.width-h;

  try{
    camera.drawRotatePic(imgC,{x:x,y:y},w,h,angDegress);
  }catch(err){
    logIt(imgName +"- Error drawing:" + err.message);
  }
  

}

function PlaySound(as_obj, a_sFileName, ab_loop){

  // as_obj = new Audio(a_sFileName);
   as_obj.src = a_sFileName
   as_obj.loop = ab_loop;
   as_obj.volume = 1.0;
   as_obj.play(); 
 }

 
 function StopSound(as_obj){
   as_obj.pause();
   //as_obj.currentTime = 0;
 
 }

 function instructNow(instStr){

  b_notSpeaking = true;
  ln_promptTime = timeLapsed - ln_configRA -1;

  instruct(instStr);


}

function instruct(instStr){

  instructBase(instStr, ln_configRA);

}

function instructBase(instStr, an_waitTime){

  logIt("in instruct for: " + instStr);
  logIt(" with b_notSpeaking:" + b_notSpeaking );
 

  if (b_notSpeaking && timeLapsed-ln_promptTime > an_waitTime){
    b_notSpeaking = false;
    

    if (b_SI){


      utterThis.text =instStr;
      utterThis.rate = ln_utterSpeed;
      utterThis.volume = 1;
      utterThis.onend = function(){
        ln_promptTime = timeLapsed;
        b_notSpeaking = true;
        logIt(" now b_notSpeaking:" + b_notSpeaking );

      };
      ln_promptTime = timeLapsed;
      synth.speak(utterThis);
      logIt("spoke: " + instStr);
      
    }
  
    return true;
    
  }else
    return false;

}

/// End Display and Sound Functions
/// Start Program Logic functions

function getPL(p, an_scaleX, an_scaleY){

  var ap_l = [];
 
  if (p[12].x > p[11].x){
    p[0].x = p[0].x/an_scaleX;
    p[0].y = p[0].y/an_scaleY;
    ap_l.push(p[0]);
    for (var i=1; i < 33; i++){


      if (i%2===0){
        p[i-1].x = p[i-1].x/an_scaleX;
        p[i-1].y = p[i-1].y/an_scaleY;
        ap_l.push(p[i-1]);
        //ap_l[i] = p[i-1];

      }else{
        p[i+1].x = p[i+1].x/an_scaleX;
        p[i+1].y = p[i+1].y/an_scaleY;
        ap_l.push(p[i+1]);
        //ap_l[i] = p[i+1];
      }

    }

  }else{
    ap_l = p;
  }

  return ap_l;

}

function getANGYX(pp,np){

  if (pp.x-np.x ===0)
    return 90;
  else
    return Math.atan(Math.abs(pp.y-np.y)/Math.abs(pp.x-np.x)) * 180 / Math.PI;

}

function getSignedANGXY(pp,np){

  if (pp.y-np.y ===0)
  return 90;
else
  return Math.atan((pp.x-np.x)/(pp.y-np.y)) * 180 / Math.PI;

}

function getANGXY(hp,lp){

  if (lp.y-hp.y ===0)
  return 90;
else
  return Math.atan(Math.abs(lp.x-hp.x)/Math.abs(lp.y-hp.y)) * 180 / Math.PI;

}

function getDistance(p1, p2){

  return Math.sqrt(Math.pow((p1.x-p2.x),2) + Math.pow((p1.y-p2.y),2));

}

function toDegrees (angle) {
  return angle * (180 / Math.PI);
}

function getArea(p1, p2, p3){

  var d12, d13,d23, p, area;
  
  d12 = getDistance(p1, p2);
  d13 = getDistance(p1, p3);
  d23 = getDistance(p2, p3);

  p = (d12,d13,d23)/2;

  area = Math.sqrt(p * (p-d12)* (p-d13)* (p-d23));
  logIt("Found area: " + area);

  return area;


}

function getANGby3Points(s, eb, w){

  var Dseb, Debw,Dsw;
  
  Dseb = getDistance(s, eb);
  Debw = getDistance(eb, w);
  Dsw = getDistance(s, w);

  //document.getElementById('angle').innerHTML = "Dseb2: " + (Dseb*Dseb) + " Debw2: " + (Debw*Debw) + " Dsw2: " + (Dsw*Dsw);

  if (Dsw+Debw+Dseb ===0 || Dseb===0||Debw===0){

    return 370;
  }else{


    return toDegrees(Math.acos((Dseb*Dseb + Debw*Debw-Dsw*Dsw)/(2*Dseb*Debw)));

    //return (Dsw/(Dsw+Debw+Dseb)) * 180;
  }

}

function getObtuseANG(ANG){

  if (ANG<0){

    return 180+ ANG;
  }else{

    return ANG;
  }

}

function checkValBetw(val, upperVal, lowerVal, msg){

 if (val < upperVal && val > lowerVal){

    return 1;
  }else{

    logIt('Failed for ' + msg + ' with values: ' + val + ' upperVal:' + upperVal + ' lowerVal:' + lowerVal );

    return 0;

  }
 
}

function generateRandomIntegerInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}



function logIt(als_log){

  ls_log += timeLapsed+": "+als_log + "\\n";
  //ls_log="";
}



function convertToMinSec(aTimeDuration){

  if (Math.floor(aTimeDuration/60)>0){
    return Math.floor(aTimeDuration/60) + "min " + Math.ceil(aTimeDuration%60)+"sec";
  }else{
    return Math.ceil(aTimeDuration%60)+"sec";
  }
  

}
/*
function calTimeDuration (aCurrentWave, aCurrDuration,ab_all){

  var ltimeDuration = aCurrDuration; 

  if (aCurrentWave>1 && ab_all){

    for (var w=1; w<aCurrentWave; w++){

      ltimeDuration = ltimeDuration + n_initGTime*(Math.pow(1+n_diff,w-1));
      
    }

  }

  
  return Math.round(ltimeDuration);


}

*/

function ValidateEmail(inputText)
{
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if(inputText.match(mailformat))
  {

   return true;
  }
  else
  {

    return false;
  }
}

function initPuzzle(){

  if (b_initLoad)
    setUpCapturing();

  var videoObj = setUpDeviceParam();
  exObj = new PuzzleObj(120);

  

}

function initGeneric(){

  var s_inAudible = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

  ad_countDown = new Audio();
  ad_countDown.src = s_inAudible;
  ad_countDown.volume=1.0;
  ad_countDown.play();

  ad_shuttle = new Audio();
  ad_shuttle.src= s_inAudible;
  ad_shuttle.volume=1.0;
  ad_shuttle.play();

  ad_tick = new Audio();
  ad_tick.src= s_inAudible;
  ad_tick.volume=1.0;
  ad_tick.play();

  setUpCapturing();

  var videoObj = setUpDeviceParam();

  utterThis.volume=1;
  utterThis.text = "Let's Go! Preparing camera";
  synth.speak(utterThis);
  document.getElementById('start').style.display = "none";
 

  document.getElementById('loading').style.display = "block";
  document.getElementById('loadingStats').style.display = "none";

}



/// End Program Logic

/// Start video capturing/ AI

async function createDetector() {
  /*

switch (STATE.model) {
    case posedetection.SupportedModels.PoseNet:
      return posedetection.createDetector(STATE.model, {
        quantBytes: 4,
        architecture: 'MobileNetV1',
        outputStride: 16,
        inputResolution: {width: 500, height: 500},
        multiplier: 0.75
      });
    case posedetection.SupportedModels.MediapipeBlazepose:
      return posedetection.createDetector(STATE.model, {quantBytes: 4});
    case posedetection.SupportedModels.MoveNet:
      const modelType = STATE.modelConfig.type == 'lightning' ?
          posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING :
          posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
      return posedetection.createDetector(STATE.model, {modelType});
  }

  */

  
  STATE.model = posedetection.SupportedModels.MediapipeBlazepose;
  return posedetection.createDetector(STATE.model, {quantBytes: 2});
  

  /*
  return await posenet.load({
    architecture: 'MobileNetV1',
    outputStride: 16,
    inputResolution: { width: 640, height: 480 },
    multiplier: 0.75
  });
  */
  
}



let getPose = async () => { 

  if (typeof(detector) === "undefined"){

    return null;

  }else{

    try{
      const poses = detector.estimatePoses(
        camera.video,
        {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});
    
        return poses;
    }catch(err){
      logIt("End Err: " + err);
      return null;
    }

  }

 };



async function setUpCapturing(){

    await tf.setBackend(STATE.backend);

    try{
      camera = await Camera.setupCamera(STATE.camera);
    }catch(err){
      alert(err);
    }
    
  
    await tf.ready();
  
    detector = await createDetector();

/* 
   if (typeof(camera) !== "undefined"){

      setInterval(() => {
        dObj = net.detect(camera.video);
      }, 100);

    }


*/
    n_commentID = setInterval(sayComment,1000);

    n_sceneID = setInterval(controlDisplay,200);
   
    n_predictID = setInterval(renderResult, 201);

    //renderPrediction();
}

function controlDisplay(){
  //n_displayMode>3
      logIt("ControlDisplay calling renderScene");
      renderScene();
   
}

function renderScene(){

  camera.drawCtx();

  exObj.show(timeLapsed);

}

async function renderResult() {


  timeLapsed = (Date.now() - startTime)/1000;

  //document.getElementById('timelapse').innerText = timeLapsed;
  //timer.innerHTML = timeLapsed

  if (b_predict){
    try{

      if (typeof(camera) !== "undefined"){

        
        if (ln_videoWidth===0){

          if (b_initLoad){
            document.getElementById('loading').style.display="none";
            document.getElementById('TimeBar').style.display="block";
            //setComment(timeLapsed,"Starting");
            
           

          }
          
          b_initLoad = false;

          ln_videoWidth =  camera.canvas.width;
          logIt("screen width:" + window.screen.width +" screen height:" + window.screen.height 
          + " canvas width:" +ln_videoWidth + " videoWidth:"+ camera.video.videoWidth );

          if (n_selection===1){
            exObj = new skaterObj(camera.canvas.width,camera.canvas.height);
            
          }
          if (n_selection===2){
            exObj = new clockObj(camera.canvas.width,camera.canvas.height);

          }else if (n_selection===3){
            exObj = new candyObj(camera.canvas.width,camera.canvas.height);
            
          }else if(n_selection===4){
            exObj = new upLimbExObj(camera.canvas.width,camera.canvas.height);
          }else if (n_selection===5){
            logIt("set up tap with 30");
            exObj = new TapObj(30);
          }else if (n_selection===6){
            logIt("set up buid with 30");
            exObj = new PuzzleObj(120);
          }else if(n_selection===7){
            startSong();
            exObj = new DanceObj();
          }        


        }


        
        if(b_inEx){
          
          logIt("in b_inEx");

          if(!b_showLine){
            //camera.drawCtx();
            

            if (b_skipframe)
            b_skipframe=false;
          //else
            //exObj.show(timeLapsed);
          }
          
          //dObj = await net.detect(camera.video);
          //exObj.nRedCount = n_level;


  
          try{ 
  
            getPose().then((value) => {
            
              //if (value && value.length ){
              if (value && value.length ){
  
                var p = value[0];
                var bx=0,by=0,bwidth=0,bheight=0;
                data = data + "\\n" 
                + timeLapsed+","+ p.keypoints[0].x+ "," + p.keypoints[0].y+ ","
                + p.keypoints[2].x+ "," + p.keypoints[2].y+ ","+ p.keypoints[5].x+ "," + p.keypoints[5].y+ ","
              
                + p.keypoints[7].x+ "," + p.keypoints[7].y +","+ p.keypoints[8].x+ "," + p.keypoints[8].y+ ","
                + p.keypoints[11].x+ "," + p.keypoints[11].y +","+ p.keypoints[12].x+ "," + p.keypoints[12].y+ ","
                + p.keypoints[13].x+ "," + p.keypoints[13].y +","+ p.keypoints[14].x+ "," + p.keypoints[14].y+ ","
                + p.keypoints[15].x+ "," + p.keypoints[15].y +","+ p.keypoints[16].x+ "," + p.keypoints[16].y+ ","
                + p.keypoints[17].x+ "," + p.keypoints[17].y +","+ p.keypoints[18].x+ "," + p.keypoints[18].y+ ","
                + p.keypoints[19].x+ "," + p.keypoints[19].y +","+ p.keypoints[20].x+ "," + p.keypoints[20].y+ ","
                + p.keypoints[21].x+ "," + p.keypoints[21].y +","+ p.keypoints[22].x+ "," + p.keypoints[22].y+ ","
                + p.keypoints[23].x+ "," + p.keypoints[23].y +","+ p.keypoints[24].x+ "," + p.keypoints[24].y+ ","
                + p.keypoints[25].x+ "," + p.keypoints[25].y +","+ p.keypoints[26].x+ "," + p.keypoints[26].y+ ","
                + p.keypoints[27].x+ "," + p.keypoints[27].y +","+ p.keypoints[28].x+ "," + p.keypoints[28].y+ ","
                + p.keypoints[29].x+ "," + p.keypoints[29].y +","+ p.keypoints[30].x+ "," + p.keypoints[30].y+ ","
                + p.keypoints[31].x+ "," + p.keypoints[31].y +","+ p.keypoints[32].x+ "," + p.keypoints[32].y+ ","
                +bx+","+ by+","+ bwidth+","+ bheight+","
                + timeLapsed;  
                
              
              if (b_showLine){

                camera.drawCtx();
                camera.drawResults(value);

                //exObj.show(timeLapsed);
              }
              var p_l = getPL(value[0].keypoints, n_wSFactor,n_vSFactor);
            
              if (arry_prevPts.length>4){
                exObj.assess(p_l, timeLapsed,arry_prevPts);
              }
  
              arry_prevPts.push(p_l);
              if (arry_prevPts.length===n_poseLookBack){
                arry_prevPts = arry_prevPts.slice(1,n_poseLookBack);
              }
                

            } //valid values
                
          }); // get pose
  
  
          }catch(cErr){
  
  
            logIt("Err drawing ctx:" + cErr.message);
  
          }

          //sayComment(timeLapsed);

        }else{ // Ex ended

          //showEndBanner(exObj.n_bannerY);

        }

        //sayComment(timeLapsed);


        
      }


    }catch(ex){

      logIt("Stopping video Err:" + ex )

    }
  }
  
} // RenderResult


async function renderPrediction() {
 
if (b_timer){
  try{

    if (document.getElementById('loadingStats').style.display!=="none"){

      var loadingTime = (Date.now() - startTime)/1000;
      document.getElementById('estLoadStats').innerText = Math.floor(loadingTime/n_1PerLoadTime);
    }
    

    await renderResult();

    /* 
    
        if (typeof(camera) !== "undefined"){
      var obj = await net.detect(camera.video);
  
      obj.forEach(prediction => {
     
        if (prediction['class'].includes("racket")){
          logIt("Found racket");
          camera.drawRect(prediction);
          [bx, by, bwidth, bheight] = prediction['bbox'];
        }
      });

    }
    
    */

    rafId = requestAnimationFrame(renderPrediction);

   }catch(err1){
    logIt("Error under renderPrediction:"  + err1);
    //instruct("Err in renderPrediction");
   }
      
   

}


};

/// End video capturing/AI

function sendData(aFileName){

  b_timer = false;
    
  const axios = require('axios');

  var currentdate = new Date();
  var newFileName = aFileName
  +"_"+ currentdate.getDate() + "_"
  + (currentdate.getMonth()+1)  + "_" 
  + "t"  
  + currentdate.getHours() + "_"  
  + currentdate.getMinutes() + "_" 
  + currentdate.getSeconds();
  var bodyFormData = new FormData();

  bodyFormData.append('afileName', newFileName);
  bodyFormData.append('csvData', data);
  bodyFormData.append('csvLog', ls_log);

  var s_url="";

  s_url = "https://csm2.achieversprofile.com/c/d?handler=Log";


  axios({
    method: "post",
    url: s_url,
   
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      //handle success
      //console.log(response); w:480, H:640
      b_timer = false;
           

    })
    .catch(function (response) {
      //handle error

      b_timer = false;
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}

function sendTime(aTeamName, aTiming){

  //b_timer = false;
    
  const axios = require('axios');

  var bodyFormData = new FormData();

  bodyFormData.append('teamName', aTeamName);
  bodyFormData.append('timing', aTiming);
 

  var s_url="";

  if (n_server>0)
    s_url = "https://csm2.achieversprofile.com/c/d?handler=RecNC";
  else  
    s_url = "https://localhost:44349/c/d?handler=RecNC";



  axios({
    method: "post",
    url: s_url,
   
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      //handle success
      //console.log(response); w:480, H:640
      //b_timer = false;
           

    })
    .catch(function (response) {
      //handle error

      //b_timer = false;
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}

function getContent(){

  //b_timer = false;
    
  const axios = require('axios');

  var s_url="";

  if (n_server>0){
    s_url = "https://csm2.achieversprofile.com/c/d?handler=GetTable";
  }else{
    s_url = "https://localhost:44349/c/d?handler=GetTable";
  }

  axios({
    method: "post",
    url: s_url,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {

      /*

            <td class="tg-itoz">5</td>
            <td class="tg-itoz"><span id=nick5></span>></td>
            <td class="tg-itoz"><span id=wc5></span>></td>
            <td class="tg-itoz"><span id=sk5></span>></td>
            <td class="tg-itoz"><span id=ph5></span>></td>
            <td class="tg-itoz"><span id=sc5></span>></td>
            <td class="tg-itoz"><span id=gd5></span>></td>

      */
      

      var ls_ranking = response;

      var arryRecs = response.data.content.split(";");

      let rankedTeam=[];

      for (var r=0; r < arryRecs.length; r++){

        let rawItem = arryRecs[r].split(",");
        if (rawItem[0].length>0)
          rankedTeam.push({n:rawItem[0],t:parseInt(rawItem[1],10)});

      }

      rankedTeam.sort((a,b)=>(a.t>b.t)?1:-1);




      var s_tableHead = "<table class='tg'><tr><th class='tg-my09'>Rank</th><th class='tg-my09'>Team Name</th><th class='tg-my09'>Time</th> </tr>";

      var s_tableBottom = "</table>";
      var s_tableContent="";
      for (var r=0; r < rankedTeam.length; r++){

        s_tableContent = s_tableContent + " <tr>";

        var arryfields = arryRecs[r].split(",");

        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+(r+1)+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+rankedTeam[r].n+"</td>";
        s_tableContent = s_tableContent + "<td class='tg-itoz'>"+rankedTeam[r].t+"</td>";
        
/*
        document.getElementById('nick' + (r+1)).innerHTML= arryfields[1];
        document.getElementById('wc' + (r+1)).innerHTML= arryfields[2];
        document.getElementById('sk' + (r+1)).innerHTML= arryfields[3];
        document.getElementById('ph' + (r+1)).innerHTML= arryfields[4];
        document.getElementById('sc' + (r+1)).innerHTML= arryfields[5];
        document.getElementById('gd' + (r+1)).innerHTML= convertToMinSec(parseInt(arryfields[6]));
        */

        s_tableContent = s_tableContent + " </tr>";

        

      }
      document.getElementById('teamRanked').innerHTML = s_tableHead+ s_tableContent + s_tableBottom;

    })
    .catch(function (response) {
      //handle error
 
      //b_timer = false;
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}

async function PostUpdateRec(aMobile, aReward,adExpiry,auserInfo){

  logIt("In PostUpdateRec");
  var bodyFormData = new FormData();
  bodyFormData.append('aReward', aReward);
  bodyFormData.append('aMobile', aMobile);

  var s_url = "";



  if (n_server>0){
    s_url = "https://csm2.achieversprofile.com/ExerciseRewards/Create?handler=UpdateRec";
  }else{
    s_url = "https://localhost:44349/ExerciseRewards/Create?handler=UpdateRec";
  }

  logIt("Updating to url: " + s_url);

  const axios = require('axios');
   
  axios({
    method: "post",
    url: s_url,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {

      if (response.data.returnMsg==="Ok"){

        if (aReward==="T"){

          d_lastReward = Date.now();
          logIt("set last reward:" + d_lastReward);
          document.cookie = "DateLastReward="+d_lastReward+"; expires=" + adExpiry.toUTCString(); // Date user last obtained reward
        }
          

        document.cookie = "DateLastPlayed="+Date.now()+"; expires=" + adExpiry.toUTCString(); // Date completed an exercise and sync with server
        document.cookie = "MyCouponCount="+response.data.myCouponCount+"; expires=" + adExpiry.toUTCString();
    
        logIt("set MyCouponCount" + response.data.myCouponCount);
        auserInfo = document.cookie;
    
        //document.getElementById('blsr').style.display ="none";
        // eslint-disable-next-line no-restricted-globals
        //location.reload();

      }else{

        //logIt("Error ");
        alert("An error has occurred. Please inform admin that: " + response.data.returnMsg);
      }

    })
    .catch(function (response) {

      alert("A network error has occurred.");
      //return response.data.returnMsg;

    });
  

}

async function PostNewRec(aName, aMobile, adExpiry, auserInfo){

  var bodyFormData = new FormData();
  bodyFormData.append('aName', aName);
  bodyFormData.append('aMobile', aMobile);

  var s_url = "";

  if (n_server>0){
    s_url = "https://csm2.achieversprofile.com/ExerciseRewards/Create?handler=AddRec";
  }else{
    s_url = "https://localhost:44349/ExerciseRewards/Create?handler=AddRec";
  }


  const axios = require('axios');

  axios({
    method: "post",
    url: s_url,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {

      if (response.data.returnMsg==="Ok"){

        document.cookie = "BLSRName="+document.getElementById('nameBLS').value + "; expires=" + adExpiry.toUTCString();
        document.cookie = "BLSRMobile="+document.getElementById('mobileBLS').value + "; expires=" + adExpiry.toUTCString();
        document.cookie = "DateLastPlayed=-1; expires=" + adExpiry.toUTCString(); // Date completed an exercise and sync with server
        document.cookie = "DateLastReward=-1; expires=" + adExpiry.toUTCString(); // Date user last obtained reward
        document.cookie = "MyCouponCount=0; expires=" + adExpiry.toUTCString();
    
        auserInfo = document.cookie;
    
        document.getElementById('blsr').style.display ="none";
        // eslint-disable-next-line no-restricted-globals
        location.reload();

      }else{

        alert("An error has occurred. Please inform admin that: " + response.data.returnMsg);
      }

    })
    .catch(function (response) {

      alert("A network error has occurred. Status:" + response.status);
      //return response.data.returnMsg;

    });


}



function ReportIssue(){

  b_timer=false;

  if (n_server > 0){
    var nVer = navigator.appVersion;

    ls_log = "Agent: " +nVer+"\\n" 
    + "Device: " + document.getElementById('DeviceDesc').value + "\\n"
    + "Problem: "+document.getElementById('problemDesc').value + "\\n" + ls_log;

    sendData("SeniorIssues");

  }

}

function isFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

function prizeRandomizer(aWinLevel){

  logIt("d_lastReward:" + d_lastReward 
  + " formatDate(new Date(Date.now())):" +formatDate(new Date(Date.now())) );
  if (d_lastReward===-1 ||formatDate(d_lastReward)!==formatDate(new Date(Date.now()))){

    if (generateRandomIntegerInRange(0,100) < aWinLevel){

      logIt("Prize is true");

      return true;

    }else{
      logIt("Prize is false");
      return false;
    }
    

  }else{

    return false;

  }
  

}

function formatDate (aDate){

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oc", "Nov", "Dec"];
    
    const month = monthNames[aDate.getMonth()];
    const day = String(aDate.getDate()).padStart(2, '0');
    const year = aDate.getFullYear();
    return  day +" "+ month  + " " + year;

}

function selectClimber(anStage){

 // document.getElementById('start').addEventListener("click", function(){


//    ad_background= new Audio();
 //   ad_background.src = "jingShe.mp3";
 //   ad_background.volume = 0.2;
 //   ad_background.loop = true;
//    ad_background.play();

 
 // });

  document.getElementById('blsrProgress').style.display="none";
  document.getElementById('Landing').style.display="none";
  document.getElementById('ExView').style.display="block";
  //document.getElementById('RefVideo').src = "https://www.youtube.com/embed/REYyQBOVIIU";
  document.documentElement.scrollTop =0;

  b_timer=true;
  b_inEx=true;
  n_selection=anStage;
  initGeneric();
  document.getElementById('start').style.display="none";
  document.getElementById('TimeBar').style.display="block";

  //document.getElementById('scoreImg').src ="T_chineseGold.png" ;
  document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });

}

function startGame(){

  document.getElementById('blsrProgress').style.display="none";
  document.getElementById('Landing').style.display="none";
  document.getElementById('ExView').style.display="block";
  
  document.documentElement.scrollTop =0;
 
  b_timer=true;
  b_inEx=true;

  if (b_initLoad){

    setUpCapturing();
    setUpDeviceParam();
    document.getElementById('loading').style.display = "block";

  }

}

function DanceGame(){

  startGame();
  n_selection=7;
  exObj = new DanceObj();

}

function PuzzleGame(){
  startGame();
  n_selection=6;
 
   //initGeneric();
   //initPuzzle(); 
 
  exObj = new PuzzleObj(120);
 
  document.getElementById('loadingStats').style.display = "none";
  document.getElementById('start').style.display="none";
  document.getElementById('TimeBar').style.display="block";
 
  //document.getElementById('scoreImg').src ="T_chineseGold.png" ;
  document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
}



///End External interaction

async function app() {

/*
  let testArr = [{n:"test",t:100},{n:"test1",t:101},{n:"test2",t:95}];

  testArr.sort((a,b)=>(a.t>b.t)?1:-1);

  */


  //getContent();

    
  audioCtx = new AudioContext();
  song = new Audio("DancingQueen.mp3");

  source = audioCtx.createMediaElementSource(song);

  source.connect(audioCtx.destination);

  gainNode = audioCtx.createGain();

  source.connect(gainNode).connect(audioCtx.destination);



  arryLen=6;
  n_mode=1;
  n_state=-3;

  n_selection=5;

  var url_string = window.location.href; //
  var url = new URL(url_string);

  var state = url.searchParams.get("state");

  if (state==="bp"){

    selectClimber(4);

  }else if (state==="dm"){
    selectClimber(5);
  }else if (state==="nc"){
    document.getElementById('nc').style.display="block";
    getContent();

    document.getElementById('Pose').addEventListener("click", function(){

      exObj.nPhase=-1;

    });

    document.getElementById('Ypose').addEventListener("click", function(){

      exObj.nPhase=0;
      
      exObj.nStartTime =timeLapsed;

    });

    document.getElementById('br').addEventListener("click", function(){

    sendData("ccBottom2");


    });

    document.getElementById('Gend').addEventListener("click", function(){


      b_predict=false;
      //b_runTime = false;
      b_displayTime=false;

      document.getElementById('nc').style.display="block";
      
      getContent();
      
      document.documentElement.scrollTop =0;
      document.getElementById('ExView').style.display="none";
     
      


    });


    document.getElementById('Demo').addEventListener("click", function(){

      b_predict=true;
      b_actualPlay = false;
      //b_inEx = true;
      b_displayTime=false;
      if (document.getElementById('teamName').value.length>0){

        if (/^[a-zA-Z]+$/.test(document.getElementById('teamName').value)){

          startTime = Date.now();
          timeLapsed = 0;
          s_teamName = document.getElementById('teamName').value;
  
          document.getElementById('tName').innerText = s_teamName;
  
  
          PuzzleGame();

        }else
        alert(document.getElementById('teamName').value + " is invalid. Only letters are allowed.");


        //selectClimber(6);
        //sendTime(s_teamName,91);

      }else{
        alert("Team name cannot be empty");
      }
      
  
    });

    document.getElementById('Play').addEventListener("click", function(){

      b_predict=true;
      b_actualPlay = true;
      //b_inEx = true;
      b_displayTime=false;
      if (document.getElementById('teamName').value.length>0){

        if (/^[a-zA-Z]+$/.test(document.getElementById('teamName').value)){

          startTime = Date.now();
          timeLapsed = 0;
          s_teamName = document.getElementById('teamName').value;
  
          document.getElementById('tName').innerText = s_teamName;
  
  
          PuzzleGame();

        }else
        alert(document.getElementById('teamName').value + " is invalid. Only letters are allowed.");


        //selectClimber(6);
        //sendTime(s_teamName,91);

      }else{
        alert("Team name cannot be empty");
      }
      
  
    });

    //selectClimber(6);
    
  }else if(state==="mg"){

    document.getElementById('mg').style.display="block";
    
    document.getElementById('Dance').addEventListener("click", function(){

      document.getElementById('mg').style.display="none";
      document.getElementById('start').style.display="none";

     
      //start
      b_predict=true;
      b_actualPlay = false;
      //b_inEx = true;
      b_displayTime=false;

      var s_inAudible = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
 
      //song =new Audio();
      song.src = s_inAudible;
      song.volume=1.0;
      song.play();

      DanceGame();

      song.src = "DancingQueen.mp3";
      //startSong();
  
    });


  }else{

    //document.body.style.backgroundImage="url('bgTreasureHunt.jpg')";
    document.body.style.background = "url('bgTreasureHunt.jpg') no-repeat center fixed";
    document.body.style.backgroundSize = "cover";
    

    document.getElementById('bannerImg').style.display="block";
    document.getElementById('help').style.display="block";
    
    
    document.getElementById('Landing').style.display="block";
    document.getElementById('Contributors').style.display="block";
    document.getElementById('Reference').style.display="block";

  }


  dExpiry = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  
  //var dYesterday = new Date(new Date().setDate(new Date().getDate() -2));
  //document.cookie = "DateLastPlayed=-1;";

  userInfo = "";
  userInfo = document.cookie;

  document.getElementById('showPiece').addEventListener("click", function(){

    exObj.bShowPiece=true;

  });

  document.getElementById('snap').addEventListener("click", function(){

    
    exObj.arrySnapped.push(exObj.sMP);
    exObj.sMP="";
    exObj.bShowPiece=true;


  });
    

  document.getElementById('update').addEventListener("click", function(){
    
    PostUpdateRec("12345678","T",dExpiry,userInfo);

    
  });

  if (userInfo.includes("BLSRName") ){

    document.getElementById('blsrProgress').style.display ="block";

    var arryKeyValue = userInfo.split(";");
    if (arryKeyValue.length>0){

      for(var c=0; c<arryKeyValue.length; c++ ){

        var arrySplit = arryKeyValue[c].split("=");

        if (arrySplit[0].trim()==="BLSRName"){
          document.getElementById('residentName').innerText = arrySplit[1].trim();
          s_name = arrySplit[1].trim();

        }else if (arrySplit[0].trim()==="BLSRMobile"){
          s_mobile = arrySplit[1].trim();
          document.getElementById('mobilePostfix').innerText = s_mobile.substr(4);
          //n_tierAchieved = parseInt(arrySplit[1].trim());


        }else if (arrySplit[0].trim()==="DateLastPlayed"){
          if (arrySplit[1].trim()!=="-1"){
            d_lastPlay = new Date(parseInt(arrySplit[1].trim()));
            document.getElementById('DateLastPlayed').innerText = formatDate(d_lastPlay);

          }else{
            document.getElementById('DateLastPlayed').innerText = "N.A.";
            d_lastPlay = -1;
          }

        }else if (arrySplit[0].trim()==="DateLastReward"){

          if (arrySplit[1].trim()!=="-1"){
            d_lastReward = new Date(parseInt(arrySplit[1].trim()));
            formatDate(d_lastReward);
            //document.getElementById('DateLastPlayed').innerText = formatDate(d_lastPlay);

          }else{
            //document.getElementById('DateLastPlayed').innerText = "N.A.";
            d_lastReward = -1;
          }


        }else if (arrySplit[0].trim()==="MyCouponCount"){
         
          document.getElementById('MyCouponCount').innerText = arrySplit[1].trim();
          n_myCoupon = parseInt(arrySplit[1].trim());

          
        }

      }

    }


  }else{ // no registered details



    var mode = url.searchParams.get("mode");
    
    if (mode==="1"){
      document.getElementById('blsr').style.display ="block";
    }

    document.getElementById('register').addEventListener("click", function(){
    
      document.getElementById('registerTxt').style.display="none";
      document.getElementById('register').style.display="none";

      document.getElementById('enterName').style.display="block";
      document.getElementById('nameBLS').style.display="block";
      document.getElementById('next').style.display="block";
 
      
    });
    
    document.getElementById('next').addEventListener("click", function(){
    
      if(document.getElementById('nameBLS').value.length>0){
 
        document.getElementById('enterName').style.display="none";
        document.getElementById('nameBLS').style.display="none";
        document.getElementById('next').style.display="none";

        document.getElementById('enterMobile').style.display="block";
        document.getElementById('mobileBLS').style.display="block";
        document.getElementById('join').style.display="block";


        /*
        document.cookie = "BLSRName="+document.getElementById('nameBLS').value + "; expires=" + dExpiry.toUTCString();
        document.cookie = "DateLastPlayed=-1; expires=" + dExpiry.toUTCString();
        document.cookie = "TierAchievered=0; expires=" + dExpiry.toUTCString();
        document.cookie = "CurrentSeq=0; expires=" + dExpiry.toUTCString();

document.cookie = "BLSRName="+document.getElementById('nameBLS').value + ";";
document.cookie = "DateLastPlayed=-1;";
document.cookie = "TierAchievered=0;";
document.cookie = "CurrentSeq=0;";

        document.cookie = "BLSRName="+document.getElementById('nameBLS').value + "; expires=" + dExpiry.toUTCString();
        document.cookie = "DateLastPlayed=-1; expires=" + dExpiry.toUTCString();
        document.cookie = "TierAchievered=0; expires=" + dExpiry.toUTCString();
        document.cookie = "CurrentSeq=0; expires=" + dExpiry.toUTCString();

        userInfo = document.cookie;

        document.getElementById('blsr').style.display ="none";
        // eslint-disable-next-line no-restricted-globals
        location.reload();

*/
    
      }else{
        alert("Enter your name to join the reward progromme.");
      }
      
    });

    document.getElementById('join').addEventListener("click", async function(){
    
      if(document.getElementById('mobileBLS').value.length>0){
 
        /*
        document.cookie = "BLSRName="+document.getElementById('nameBLS').value + "; expires=" + dExpiry.toUTCString();
        document.cookie = "DateLastPlayed=-1; expires=" + dExpiry.toUTCString();
        document.cookie = "TierAchievered=0; expires=" + dExpiry.toUTCString();
        document.cookie = "CurrentSeq=0; expires=" + dExpiry.toUTCString();

document.cookie = "BLSRName="+document.getElementById('nameBLS').value + ";";
document.cookie = "DateLastPlayed=-1;";
document.cookie = "TierAchievered=0;";
document.cookie = "CurrentSeq=0;";

*/
        await PostNewRec(document.getElementById('nameBLS').value, document.getElementById('mobileBLS').value,dExpiry,userInfo);

      }else{
        alert("Enter your mobile to join the reward progromme.");
      }
      
    });



    
  }

  /* testing 
  var dYesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  d_lastPlay =dYesterday;
  n_tierAchieved=1;
  n_myCoupon=5;
  s_name="sname";

  UpdateReward();

  */

  //net = await cocossd.load();
  //var n = Math.round(0.09/0.2);

  /*
  document.getElementById('browser').innerText="browser: "+ navigator.appVersion + ", userAgent: " + navigator.userAgent
  +", vendor:" + navigator.vendor + ", opera: " + window.opera;

  */

  /*

  if ((!(navigator.appVersion.includes("Safari")||navigator.appVersion.includes("Chrome")))||isFacebookApp()){

    document.getElementById('warning').style.display="block";
    document.getElementById('Landing').style.display="none";
  }

  */

  logIt("Program initiated");

// 1.1.3: send device status to admin
// 1.1.4: fix sound & black screen.
  //document.getElementById('ver').innerHTML="ver 1.1.5";
  //document.getElementById('email').style.fontSize = "15px";

  if (window.screen.width < 500){

    document.getElementById('UseVideo').style.width = 0.8*window.screen.width+"px";
    document.getElementById('UseVideo').style.height = (280*window.screen.width/500)+"px";

    //document.getElementById('RefVideo').style.width = window.screen.width+"px";
    //document.getElementById('RefVideo').style.height = (280*window.screen.width/500)+"px";
   

    document.getElementById('blsrProgress').style.width = window.screen.width+"px";
    //document.getElementById('blsrProgress').style.height = (280*window.screen.width/500)+"px";


    document.getElementById('loading').style.width = window.screen.width+"px";
    document.getElementById('loading').style.height = (280*window.screen.width/500)+"px";

    document.getElementById('upperGif').style.width = 0.8*window.screen.width+"px";
    document.getElementById('skaterGif').style.width = 0.8*window.screen.width+"px";
    document.getElementById('candyGif').style.width = 0.8*window.screen.width+"px";
    document.getElementById('clockGif').style.width = 0.8*window.screen.width+"px";

  }

  //getRank();

  //checkCreateSessionRec(s_pc,"tester");

  startTime = Date.now();
  b_timer = true;

  var btnUpCount = document.createElement("button");
    
  //btnShowBird.innerHTML = "Show";
  btnUpCount.innerHTML = "Show";
  btnUpCount.addEventListener("click", function() {

    exObj.nGameDuration = 300;
    exObj.nStage = 2;
    exObj.oSwingObj.nPhase = 1;
  
  });

  var btnUpPhase = document.createElement("button");
    
  //btnShowBird.innerHTML = "Show";
  btnUpPhase.innerHTML = "Level+";
  btnUpPhase.addEventListener("click", function() {

    exObj.oSwingObj.nPhase++;

    if (exObj.oSwingObj.nPhase===5){
      exObj.oLiftObj.nPhase = 1;
      
    }
    b_skipframe=true;


    //n_phase++;
    //n_state=1;
  
  });

  var btnTarget = document.createElement("button");
    
  //btnShowBird.innerHTML = "Show";
  btnTarget.innerHTML = "Target";
  btnTarget.addEventListener("click", function() {

    exObj.oLiftObj.nLevel = 5;
  
  });

//document.getElementById('canvasContainer').appendChild(btnUpCount);
//document.getElementById('canvasContainer').appendChild(btnUpPhase);

//document.getElementById('canvasContainer').appendChild(btnTarget);

document.getElementById('send').addEventListener("click", function(){

  sendData("getPoints");

});

document.getElementById('climber').addEventListener("click", function(){

  selectClimber(4);
  
});

document.getElementById('skater').addEventListener("click", function(){

  document.getElementById('start').addEventListener("click", function(){


    ad_background= new Audio();
    ad_background.src = "chunFeng.mp3";
    ad_background.volume = 0.2;
    ad_background.loop = true;
    ad_background.play();

    n_selection=1;
    initGeneric();
    document.getElementById('start').style.display="none";
    document.getElementById('TimeBar').style.display="block";
    //document.getElementById('scoreImg').src ="T_fairyPeach.png";
   
    document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });
  });

  document.getElementById('blsrProgress').style.display="none";
  document.getElementById('Landing').style.display="none";
  document.getElementById('ExView').style.display="block";
  //document.getElementById('RefVideo').src = "https://www.youtube.com/embed/MMqbM4wEeuc";
  document.documentElement.scrollTop =0;
  

});

document.getElementById('candy').addEventListener("click", function(){

  document.getElementById('start').addEventListener("click", function(){


    ad_background= new Audio();
    ad_background.src = "daDi.mp3";
    ad_background.volume = 0.1;
    ad_background.loop = true;
    ad_background.play();
    n_selection=3;
    n_state=-3;
    logIt("started clock");

    initGeneric();
    document.getElementById('start').style.display="none";
    document.getElementById('TimeBar').style.display="block";
    //document.getElementById('scoreImg').src ="T_fb4.png" ;

    document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });

  });
  document.getElementById('blsrProgress').style.display="none";
  document.getElementById('Landing').style.display="none";
  document.getElementById('ExView').style.display="block";
  //document.getElementById('RefVideo').src = "https://www.youtube.com/embed/6Vz_xCqwJKQ";
  document.documentElement.scrollTop =0;


});

document.getElementById('clock').addEventListener("click", function(){

  document.getElementById('start').addEventListener("click", function(){


    ad_background= new Audio();
    ad_background.src = "caiShengDao.mp3";
    ad_background.volume = 0.4;
    ad_background.loop = true;
    ad_background.play();
    n_selection=2;
    logIt("started clock");

    initGeneric();

    document.getElementById('start').style.display="none";
    document.getElementById('TimeBar').style.display="block";
    //scoreImg
   // document.getElementById('scoreImg').src ="TF_cSC.png" ;
    document.getElementById('TimeBar').scrollIntoView({top: 0, behavior: 'smooth' });

  });

  
  document.getElementById('blsrProgress').style.display="none";
  document.getElementById('Landing').style.display="none";
  document.getElementById('ExView').style.display="block";
 // document.getElementById('RefVideo').src = "https://www.youtube.com/embed/pMI5-x6j45c";
  document.documentElement.scrollTop =0;


});

  document.getElementById('Restart').addEventListener("click", function(){

    // eslint-disable-next-line no-restricted-globals
    exObj.completedSegment();    
  });

  document.getElementById('help').addEventListener("click", function(){

    document.getElementById('help').style.display="none";
    document.getElementById('helpForm').style.display="block";

  });

  document.getElementById('sendProblem').addEventListener("click", function(){

    ReportIssue();

    document.getElementById("Landing").style.display="none";
    document.getElementById("ExView").style.display="none";
    document.getElementById('sendProblem').style.display="none";

    //document.getElementById("helpForm").style.display="none";
    document.getElementById("ErrSent").style.display="block"; 


  });
  
  data = "DT,nX,nY"
        +",leX,leY,reX,reY,learX,learY,rearX,rearY"
        +",lsX,lsY,rsX,rsY,lebX,lebY,rebX,rebY"
        +",lwX,lwY,rwX,rwY,lpX,lpY,rpX,rpY"
        +",liX,liY,riX,riY,ltX,ltY,rtX,rtY"
        +",lhX,lhY,rhX,rhY,lkX,lkY,rkX,rkY"
        +",laX,laY,raX,raY,lheX,lheY,rheX,rheY"
        +",lfiX,lfiY,rfiX,rfiY"
        +",bx,by,bwidth,bheight,DT";



};

app();